var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("统计报表")]),
              _c("el-breadcrumb-item", [_vm._v("销售报表")]),
              _c("el-breadcrumb-item", [_vm._v("成本销售报表")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库选择" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          multiple: "",
                          clearable: "",
                          "collapse-tags": ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseIds,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseIds", $$v)
                          },
                          expression: "searchForm.warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.salesType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "salesType", $$v)
                          },
                          expression: "searchForm.salesType"
                        }
                      },
                      _vm._l(_vm.salesTypeList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "经销商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.distributorName,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "distributorName", $$v)
                          },
                          expression: "searchForm.distributorName"
                        }
                      },
                      _vm._l(_vm.retailerList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "归属公司（销售）" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.subsidiaryId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "subsidiaryId", $$v)
                          },
                          expression: "searchForm.subsidiaryId"
                        }
                      },
                      _vm._l(_vm.subsidiaryList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.companyName, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "时间类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.timeType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "timeType", $$v)
                              },
                              expression: "searchForm.timeType"
                            }
                          },
                          _vm._l(_vm.timeTypeList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "开始日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.timeStart,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "timeStart", $$v)
                            },
                            expression: "searchForm.timeStart"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "结束日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.timeEnd,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "timeEnd", $$v)
                            },
                            expression: "searchForm.timeEnd"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "医院" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.hospitalName,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "hospitalName", $$v)
                              },
                              expression: "searchForm.hospitalName"
                            }
                          },
                          _vm._l(_vm.hospitalList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "销售单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入销售单号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.salesOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "salesOrderNo", $$v)
                            },
                            expression: "searchForm.salesOrderNo"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productNumber", $$v)
                            },
                            expression: "searchForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品名称",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productName", $$v)
                            },
                            expression: "searchForm.productName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "生产批号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入生产批号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.batchNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "batchNumber", $$v)
                            },
                            expression: "searchForm.batchNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "序列号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入序列号", clearable: "" },
                          model: {
                            value: _vm.searchForm.trackingCode,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "trackingCode", $$v)
                            },
                            expression: "searchForm.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "注册证号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入注册证号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.regNoCn,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "regNoCn", $$v)
                            },
                            expression: "searchForm.regNoCn"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "订单状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.searchForm.status,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "status", $$v)
                              },
                              expression: "searchForm.status"
                            }
                          },
                          _vm._l(_vm.statusList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "汇总方式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.reportTypeChange },
                        model: {
                          value: _vm.searchForm.reportType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "reportType", $$v)
                          },
                          expression: "searchForm.reportType"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("明细")
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("按销售单汇总 ")
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("按经销商汇总")
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("医院汇总")
                        ]),
                        _c("el-radio", { attrs: { label: 4 } }, [
                          _vm._v("按规格汇总")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "el-card",
            staticStyle: { background: "#f9f9f9", margin: "10px 0px" }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  float: "right",
                  padding: "3px 0",
                  "flex-direction": "row",
                  "font-size": "14px"
                }
              },
              [
                _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("销售金额合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.otherData.sales_unit_price_sum))
                  ]),
                  _vm._v("元")
                ]),
                _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("采购成本合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.otherData.after_tax_price_sum))
                  ]),
                  _vm._v("元")
                ]),
                _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("其他费用合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.otherData.other_expenses_sum))
                  ]),
                  _vm._v("元")
                ]),
                _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("毛利润合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.otherData.sales_gross_profit_sum))
                  ]),
                  _vm._v("元")
                ])
              ]
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          key: "dragTable" + _vm.timestamp,
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name":
              _vm.$route.name +
              (_vm.searchForm.reportType === 0
                ? "detail"
                : _vm.searchForm.reportType === 1
                ? "gatherSales"
                : _vm.searchForm.reportType === 2
                ? "gatherCustom"
                : _vm.searchForm.reportType === 3
                ? "gatherHospital"
                : "gatherSpace"),
            columns:
              _vm.searchForm.reportType === 0
                ? _vm.tableHeadDetail
                : _vm.searchForm.reportType === 1
                ? _vm.tableHeadGatherSales
                : _vm.searchForm.reportType === 2
                ? _vm.tableHeadGatherCustom
                : _vm.searchForm.reportType === 3
                ? _vm.tableHeadGatherHospital
                : _vm.tableHeadGatherSpace,
            data: _vm.tableData
          }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "size-change": _vm.pageSizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }