var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1290px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "append-to-body": true,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "top-header" }, [
        _vm.packageTypeRow.stateType === 1 || _vm.packageTypeRow.stateType === 4
          ? _c(
              "div",
              { staticClass: "search-bar" },
              [
                _c(
                  "el-form",
                  {
                    ref: "stockForm",
                    attrs: { size: "mini", "label-width": "auto" },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    },
                    model: {
                      value: _vm.stockForm,
                      callback: function($$v) {
                        _vm.stockForm = $$v
                      },
                      expression: "stockForm"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "编码" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.stockForm.productNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stockForm,
                                        "productNumber",
                                        $$v
                                      )
                                    },
                                    expression: "stockForm.productNumber"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "名称" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.stockForm.productName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stockForm,
                                        "productName",
                                        $$v
                                      )
                                    },
                                    expression: "stockForm.productName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "通用" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.stockForm.genericName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stockForm,
                                        "genericName",
                                        $$v
                                      )
                                    },
                                    expression: "stockForm.genericName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "批号" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.stockForm.batch_number,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stockForm,
                                        "batch_number",
                                        $$v
                                      )
                                    },
                                    expression: "stockForm.batch_number"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "品牌" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "", filterable: "" },
                                    model: {
                                      value: _vm.stockForm.brandId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.stockForm, "brandId", $$v)
                                      },
                                      expression: "stockForm.brandId"
                                    }
                                  },
                                  _vm._l(_vm.brandList, function(item, index) {
                                    return _c("el-option", {
                                      key: "brandId-" + index,
                                      attrs: {
                                        label: item.name,
                                        value: item.ID
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "规格型号" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.stockForm.specificationModel,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stockForm,
                                        "specificationModel",
                                        $$v
                                      )
                                    },
                                    expression: "stockForm.specificationModel"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "序列号" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.stockForm.trackingCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.stockForm,
                                        "trackingCode",
                                        $$v
                                      )
                                    },
                                    expression: "stockForm.trackingCode"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "扫码" } },
                              [
                                _c("el-input", {
                                  ref: "barcodeInput",
                                  staticClass: "barcode-input",
                                  attrs: { placeholder: "请扫描产品条码" },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.barCodeSearch.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.stockForm.barCode,
                                    callback: function($$v) {
                                      _vm.$set(_vm.stockForm, "barCode", $$v)
                                    },
                                    expression: "stockForm.barCode"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "primary" },
                                    on: { click: _vm.stockFormSubmit }
                                  },
                                  [_vm._v("搜索")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "prevent-re-click",
                                        rawName: "v-prevent-re-click"
                                      }
                                    ],
                                    attrs: { type: "primary" },
                                    on: { click: _vm.templateImport }
                                  },
                                  [_vm._v("模板导入")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "box-info" },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c("el-col", { attrs: { span: 4 } }, [
                  _vm._v("包名称：" + _vm._s(_vm.packageRow.name))
                ]),
                _c("el-col", { attrs: { span: 5 } }, [
                  _vm._v("包编码：" + _vm._s(_vm.packageRow.barCode))
                ]),
                _c("el-col", { attrs: { span: 4 } }, [
                  _vm._v("盒名称：" + _vm._s(_vm.boxRow.name))
                ]),
                _c("el-col", { attrs: { span: 3 } }, [
                  _vm._v("盒容量： "),
                  _vm.packageRow.boxType === 1
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.sumProduct()) +
                            " / " +
                            _vm._s(_vm.sumBoxVol())
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.packageRow.usage) +
                            "/" +
                            _vm._s(_vm.packageRow.capacity)
                        )
                      ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _vm._v("包备注：" + _vm._s(_vm.packageRow.remarks))
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "el-popover",
        {
          ref: "stockPopover",
          attrs: {
            placement: "bottom",
            title: "查找结果",
            width: "1000",
            trigger: "click"
          }
        },
        [
          _c("div", { staticStyle: { "text-align": "right", color: "#f00" } }, [
            _vm._v("注：双击添加记录")
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.stockList,
                border: "",
                stripe: "",
                "highlight-current-row": "",
                size: "mini",
                "max-height": "260px",
                "header-cell-style": { background: "#F5F7FA" },
                onselectstart: "return false"
              },
              on: { "row-dblclick": _vm.stockRowDbClick }
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "ID",
                  label: "序号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  property: "productNumber",
                  label: "产品编号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "200",
                  property: "productName",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  property: "specificationModel",
                  label: "规格型号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  property: "batchNumber",
                  label: "生产批号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "100",
                  property: "trackingCode",
                  label: "序列号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "stockQuantity",
                  label: "库存数量",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "normalQuantity",
                  label: "可用数量",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "unit",
                  label: "单位",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "registrationNumber",
                  label: "注册证号",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "manufacturerDate",
                  label: "生产日期",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "validityDate",
                  label: "有效期",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "",
                  label: "产地/品牌",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.productPlace) +
                            "/" +
                            _vm._s(scope.row.brandName)
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "sterilizationBatchNumber",
                  label: "灭菌批号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "sterilizationDate",
                  label: "灭菌效期",
                  align: "center"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, total",
                  align: "center",
                  total: _vm.stockForm.total,
                  "current-page": _vm.stockForm.page,
                  "page-size": _vm.stockForm.pageSize
                },
                on: { "current-change": _vm.stockListPageChange }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-popover",
        {
          ref: "consumInfoPopover",
          attrs: {
            placement: "right",
            title: "物资详情",
            width: "450",
            trigger: "click"
          },
          model: {
            value: _vm.consumInfoPopoverVisible,
            callback: function($$v) {
              _vm.consumInfoPopoverVisible = $$v
            },
            expression: "consumInfoPopoverVisible"
          }
        },
        [
          _c("i", {
            staticClass: "el-icon-close",
            staticStyle: {
              position: "absolute",
              top: "0",
              right: "0",
              width: "50px",
              height: "50px",
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              cursor: "pointer"
            },
            on: { click: _vm.consumInfoPopoverClose }
          }),
          _vm.currentPos.consumInfo
            ? _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("产品编号：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.specs.productNumber)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("产品名称：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(_vm._s(_vm.currentPos.consumInfo.main.productName))
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("生产批号：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.inventory.batch_number)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("规格型号：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.specs.specificationModel)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("注册证号：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.main.registrationNumber)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("生产日期：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(
                        _vm.currentPos.consumInfo.inventory.manufacturer_date
                      )
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("使用期限：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.inventory.validity_date)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("序列号：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.inventory.trackingCode)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("产品材料：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(_vm._s(_vm.currentPos.consumInfo.specs.material))
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("灭菌方式：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.specs.sterilizationWays)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("灭菌批号：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(
                        _vm.currentPos.consumInfo.inventory
                          .sterilization_batch_number
                      )
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("灭菌效期：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(
                        _vm.currentPos.consumInfo.inventory.sterilization_date
                      )
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("包装方式：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(
                      _vm._s(_vm.currentPos.consumInfo.specs.packNum) +
                        _vm._s(_vm.currentPos.consumInfo.specs.unit) +
                        "/" +
                        _vm._s(_vm.currentPos.consumInfo.specs.packUnit)
                    )
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("条码主码：")]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _vm._v(_vm._s(_vm.currentPos.consumInfo.specs.barCode))
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("生产厂家：")]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _vm._v(_vm._s(_vm.currentPos.consumInfo.main.manufacturer))
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24, align: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.takeOut()
                            }
                          }
                        },
                        [_vm._v("取出")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.boxRow.boxType === 1
        ? _c(
            "div",
            { staticClass: "box-list" },
            _vm._l(_vm.boxList, function(item, index) {
              return _c(
                "table",
                {
                  key: "box-" + index,
                  staticStyle: { border: "1px solid #eee", margin: "10px 0" }
                },
                [
                  _c("caption", [_vm._v(_vm._s(item.name))]),
                  _c(
                    "tr",
                    { staticStyle: { height: "30px" } },
                    [
                      _c("td"),
                      _vm._l(item.xLabel, function(xItem, xIndex) {
                        return _c("td", { key: xIndex }, [
                          _vm._v(" " + _vm._s(item.xLabel[xIndex]) + " ")
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._l(item.yLabel, function(yItem, yIndex) {
                    return _c(
                      "tr",
                      { key: yIndex, staticStyle: { height: "30px" } },
                      [
                        _c("td", [
                          _vm._v(" " + _vm._s(item.yLabel[yIndex]) + " ")
                        ]),
                        _vm._l(item.xLabel, function(xItem, xIndex) {
                          return _c(
                            "td",
                            { key: xIndex, attrs: { align: "center" } },
                            [
                              !_vm.takenCheck(item, xIndex, yIndex)
                                ? _c("i", {
                                    class:
                                      "fa fa-icon fa-circle-o" +
                                      (_vm.currentPos.id === item.ID &&
                                      _vm.currentPos.x === xIndex &&
                                      _vm.currentPos.y === yIndex
                                        ? " fa-selected"
                                        : ""),
                                    attrs: { "aria-hidden": "true" },
                                    on: {
                                      click: function($event) {
                                        return _vm.posClick(
                                          item.ID,
                                          xIndex,
                                          yIndex
                                        )
                                      }
                                    }
                                  })
                                : _c("i", {
                                    class:
                                      "fa fa-icon fa-plus-circle text-primary" +
                                      (_vm.currentPos.id === item.ID &&
                                      _vm.currentPos.x === xIndex &&
                                      _vm.currentPos.y === yIndex
                                        ? " fa-selected"
                                        : ""),
                                    attrs: { "aria-hidden": "true" },
                                    on: {
                                      click: function(e) {
                                        return _vm.posView(
                                          item.ID,
                                          xIndex,
                                          yIndex,
                                          e
                                        )
                                      }
                                    }
                                  })
                            ]
                          )
                        })
                      ],
                      2
                    )
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm.boxRow.boxType === 2 || _vm.boxRow.boxType === 3
        ? _c(
            "div",
            { staticClass: "consum-list" },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _vm.consumForm.orderByProductNumber
                    ? _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "primary", closable: "" },
                          on: {
                            close: function($event) {
                              return _vm.sortCloseTag("orderByProductNumber")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "产品编号：" +
                              _vm._s(
                                _vm.consumForm.orderByProductNumber === "asc"
                                  ? "升序"
                                  : "倒序"
                              )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.consumForm.orderByProductName
                    ? _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "primary", closable: "" },
                          on: {
                            close: function($event) {
                              return _vm.sortCloseTag("orderByProductName")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "物资名称：" +
                              _vm._s(
                                _vm.consumForm.orderByProductName === "asc"
                                  ? "升序"
                                  : "倒序"
                              )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.consumForm.orderBySpecificationModel
                    ? _c(
                        "el-tag",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "primary", closable: "" },
                          on: {
                            close: function($event) {
                              return _vm.sortCloseTag(
                                "orderBySpecificationModel"
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "规格型号：" +
                              _vm._s(
                                _vm.consumForm.orderBySpecificationModel ===
                                  "asc"
                                  ? "升序"
                                  : "倒序"
                              )
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.consumInfoLoading,
                      expression: "consumInfoLoading"
                    }
                  ],
                  ref: "consumInfoListTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.consumInfoList,
                    size: "small",
                    "highlight-current-row": "",
                    border: "",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      borderColor: "#ebeef5",
                      color: "#333"
                    }
                  },
                  on: { "row-click": _vm.consumInfoRowClick }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "80",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.consumForm.page - 1) *
                                      _vm.consumForm.pageSize +
                                      scope.$index +
                                      1
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      8576832
                    )
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "specs.productNumber",
                        label: "产品编号",
                        width: "100",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            class: _vm.sortClass(
                              _vm.consumForm.orderByProductNumber
                            ),
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center",
                              cursor: "pointer"
                            },
                            attrs: { align: "center" },
                            on: {
                              click: function($event) {
                                return _vm.sortChange("orderByProductNumber")
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("产品编号")]),
                            _c("span", { staticClass: "caret-wrapper" }, [
                              _c("i", { staticClass: "sort-caret ascending" }),
                              _c("i", { staticClass: "sort-caret descending" })
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "main.productName",
                        label: "物资名称",
                        width: "200",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            class: _vm.sortClass(
                              _vm.consumForm.orderByProductName
                            ),
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center",
                              cursor: "pointer"
                            },
                            attrs: { align: "center" },
                            on: {
                              click: function($event) {
                                return _vm.sortChange("orderByProductName")
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("物资名称")]),
                            _c("span", { staticClass: "caret-wrapper" }, [
                              _c("i", { staticClass: "sort-caret ascending" }),
                              _c("i", { staticClass: "sort-caret descending" })
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "specs.genericName",
                      label: "通用名称",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        prop: "specs.specificationModel",
                        label: "规格型号",
                        width: "100",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            class: _vm.sortClass(
                              _vm.consumForm.orderBySpecificationModel
                            ),
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center",
                              cursor: "pointer"
                            },
                            attrs: { align: "center" },
                            on: {
                              click: function($event) {
                                return _vm.sortChange(
                                  "orderBySpecificationModel"
                                )
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v("规格型号")]),
                            _c("span", { staticClass: "caret-wrapper" }, [
                              _c("i", { staticClass: "sort-caret ascending" }),
                              _c("i", { staticClass: "sort-caret descending" })
                            ])
                          ]
                        )
                      ])
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.batch_number",
                      label: "生产批号",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.trackingCode",
                      label: "序列号",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quantityUsed",
                      label: "数量",
                      width: "170",
                      align: "center"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.packageTypeRow.stateType === 1 ||
                              _vm.packageTypeRow.stateType === 4
                                ? _c("el-input-number", {
                                    attrs: { size: "mini" },
                                    on: {
                                      change: function($event) {
                                        return _vm.quantityUsedChange(row)
                                      }
                                    },
                                    model: {
                                      value: row.quantityUsed,
                                      callback: function($$v) {
                                        _vm.$set(row, "quantityUsed", $$v)
                                      },
                                      expression: "row.quantityUsed"
                                    }
                                  })
                                : _c("span", [_vm._v(_vm._s(row.quantityUsed))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1144620457
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.normalQuantity",
                      label: "库存可用",
                      width: "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.manufacturer_date",
                      label: "生产日期",
                      width: "100",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.validity_date",
                      label: "有效期",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.sterilization_batch_number",
                      label: "灭菌批号",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventory.sterilization_date",
                      label: "灭菌效期",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "产地/品牌",
                      width: "200",
                      align: "center",
                      "show-overflow-tooltip": ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                _vm._s(row.main.productPlace) +
                                  "/" +
                                  _vm._s(row.main.brandName)
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2815835861
                    )
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "main.manufacturer",
                      label: "生产厂家",
                      width: "200",
                      align: "center",
                      "show-overflow-tooltip": ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "100",
                      align: "center",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.packageTypeRow.stateType === 1 ||
                              _vm.packageTypeRow.stateType === 4
                                ? _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "prevent-re-click",
                                          rawName: "v-prevent-re-click"
                                        }
                                      ],
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeProduct(row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1045514871
                    )
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      align: "center",
                      total: _vm.consumForm.total,
                      "current-page": _vm.consumForm.page,
                      "page-sizes": [5, 10, 20, 50, 100],
                      "page-size": _vm.consumForm.pageSize
                    },
                    on: {
                      "current-change": _vm.consumFormPageChange,
                      "size-change": _vm.consumFormSizeChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("ImportComponents", {
        ref: "ImportComponents",
        on: {
          reload: function($event) {
            return _vm.init(_vm.boxRow)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }