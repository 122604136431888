var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true,
        fullscreen: _vm.dialogFullScreen
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticClass: "custom_dialog_header" }, [
          _c("span", { staticClass: "el_dialog_title" }, [
            _vm._v(_vm._s(_vm.dialogTitle))
          ]),
          _c(
            "div",
            {
              staticClass: "custom_dialog_menu",
              on: {
                click: function($event) {
                  _vm.dialogFullScreen = !_vm.dialogFullScreen
                }
              }
            },
            [
              !_vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-maximize",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e(),
              _vm.dialogFullScreen
                ? _c("i", {
                    staticClass: "fa fa-window-restore",
                    attrs: { "aria-hidden": "true" }
                  })
                : _vm._e()
            ]
          )
        ])
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-width": "120px",
            inline: true,
            size: "small",
            "label-suffix": ":"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "不动销时间范围", prop: "value" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.notMoving,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "notMoving", $$v)
                    },
                    expression: "form.notMoving"
                  }
                },
                _vm._l(_vm.notMovingList, function(item, index) {
                  return _c("el-option", {
                    key: "notMoving" + index,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.formSubmit } },
                [_vm._v("设置保存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.dataList,
            size: "small",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            }
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "50",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.form.page - 1) * _vm.form.pageSize +
                            scope.$index +
                            1
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "warehouse.name",
              label: "仓库",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "notMovingDay",
              label: "不动销天数",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "main.productName",
              label: "物资名称",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specs.productNumber",
              label: "产品编号",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "specs.specificationModel",
              label: "规格型号",
              "min-width": "150",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "stock_quantity",
              label: "库存数量",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "normalQuantity",
              label: "可用数量",
              width: "100",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "150",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.toStock(row)
                          }
                        }
                      },
                      [_vm._v("查看库存明细")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.form.total,
              "current-page": _vm.form.page,
              "page-size": _vm.form.pageSize,
              "page-sizes": [10, 20, 30, 40, 50, 100]
            },
            on: {
              "size-change": _vm.sizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }