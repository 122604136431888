var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticStyle: { display: "flex", "flex-direction": "row" } }, [
        _c(
          "div",
          { staticStyle: { width: "200px", border: "1px solid #CFD7E5" } },
          [
            _c("el-tree", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.packagesTypeLoading,
                  expression: "packagesTypeLoading"
                }
              ],
              ref: "packagesTypeTree",
              staticClass: "filter-tree",
              attrs: {
                data: _vm.packageTypeList,
                props: _vm.packagesTypeProps,
                "default-expand-all": "",
                "highlight-current": true,
                "expand-on-click-node": false,
                "node-key": "ID"
              },
              on: { "node-click": _vm.packageTypeClick },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c("span", {}, [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        data.ID === 0
                          ? _c("i", {
                              class: node.expanded
                                ? "folder el-icon-folder-opened"
                                : "folder el-icon-folder"
                            })
                          : _c("i", {
                              staticClass: "fa fa-medkit",
                              attrs: { "aria-hidden": "true" }
                            }),
                        _vm._v(" " + _vm._s(data.name))
                      ])
                    ])
                  }
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              flex: "1",
              "margin-left": "20px",
              border: "1px solid #CFD7E5"
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.packageSubTypeListLoading,
                    expression: "packageSubTypeListLoading"
                  }
                ],
                ref: "packageSubTypeListTable",
                attrs: {
                  data: _vm.packageSubTypeList,
                  size: "mini",
                  "highlight-current-row": "",
                  "row-key": _vm.rowKey,
                  "row-class-name": _vm.rowClassName
                },
                on: { "row-dblclick": _vm.packSubTypeRowDbClick }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "名称",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "barCode",
                    label: "条码",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "warehouse.name",
                    label: "所在地",
                    align: "center",
                    "show-overflow-tooltip": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          scope.row.hospitalName
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row.hospitalName))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(scope.row.warehouse.name))
                              ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "60",
                    prop: "status",
                    label: "状态",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.typeStateFormatter(row.stateType)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }