var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "950px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "productForm",
          attrs: {
            model: _vm.productForm,
            "label-width": "100px",
            size: "mini",
            disabled: true,
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "物资名称" } }, [
                    _vm._v(" " + _vm._s(_vm.productForm.productName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "品牌" } }, [
                    _vm._v(" " + _vm._s(_vm.productForm.brandName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "产地" } }, [
                    _vm._v(" " + _vm._s(_vm.productForm.productPlace) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "所属分类" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.productForm.classificationText) + " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "生产厂家" } }, [
                    _vm._v(" " + _vm._s(_vm.productForm.manufacturer) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "供应商" } }, [
                    _vm._v(" " + _vm._s(_vm.productForm.supplier) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "注册证号" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.productForm.registrationNumber) + " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "注册日期" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.registrationRow
                                    ? _vm.registrationRow.regDate
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "失效日期" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.registrationRow
                                    ? _vm.registrationRow.expirationDate
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            "label-width": "120px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            { staticClass: "el-row", attrs: { type: "flex" } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品编码", prop: "productNumber" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入产品编码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.productNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "productNumber", $$v)
                          },
                          expression: "itemForm.productNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.specsRow.main.type === "工具",
                      expression: "specsRow.main.type==='工具'"
                    }
                  ],
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工具名称", prop: "toolName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入工具名称",
                          clearable: "",
                          maxlength: "50"
                        },
                        model: {
                          value: _vm.itemForm.toolName,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "toolName", $$v)
                          },
                          expression: "itemForm.toolName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.specsRow.main.type === "工具",
                      expression: "specsRow.main.type==='工具'"
                    }
                  ],
                  attrs: { span: 8 }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "注册证号", prop: "registrationNumber" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择注册证号",
                            clearable: "",
                            filterable: ""
                          },
                          model: {
                            value: _vm.itemForm.registrationNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "registrationNumber", $$v)
                            },
                            expression: "itemForm.registrationNumber"
                          }
                        },
                        _vm._l(_vm.registrationList, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.ID,
                              attrs: {
                                label: item.regNoCn,
                                value: item.regNoCn,
                                disabled: item.state !== 1
                              }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.regNoCn))
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "12px"
                                  }
                                },
                                [_vm._v(_vm._s(item.regProductName))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "条码主码", prop: "barCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入条码主码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.barCode,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "barCode", $$v)
                          },
                          expression: "itemForm.barCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "规格型号", prop: "specificationModel" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入规格型号",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.specificationModel,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "specificationModel", $$v)
                          },
                          expression: "itemForm.specificationModel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单位", prop: "unit" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择单位" },
                          model: {
                            value: _vm.itemForm.unit,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "unit", $$v)
                            },
                            expression: "itemForm.unit"
                          }
                        },
                        _vm._l(_vm.packUnitList, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包装数量", prop: "packNum" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入包装数量",
                          step: 1,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.packNum,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "packNum", $$v)
                          },
                          expression: "itemForm.packNum"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包装单位", prop: "packUnit" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择包装单位"
                          },
                          model: {
                            value: _vm.itemForm.packUnit,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "packUnit", $$v)
                            },
                            expression: "itemForm.packUnit"
                          }
                        },
                        _vm._l(_vm.packUnitList, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包装说明", prop: "packInstructions" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入包装说明",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.packInstructions,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "packInstructions", $$v)
                          },
                          expression: "itemForm.packInstructions"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "存储条件", prop: "storage" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "text",
                            placeholder: "存储条件",
                            disabled: true,
                            clearable: ""
                          },
                          model: {
                            value: _vm.itemForm.storageString,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "storageString", $$v)
                            },
                            expression: "itemForm.storageString"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "append" },
                              on: { click: _vm.viewStorageList },
                              slot: "append"
                            },
                            [_vm._v("选取")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "灭菌方式", prop: "sterilizationWays" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择灭菌方式"
                          },
                          model: {
                            value: _vm.itemForm.sterilizationWays,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "sterilizationWays", $$v)
                            },
                            expression: "itemForm.sterilizationWays"
                          }
                        },
                        _vm._l(_vm.sterilizationWaysList, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "材质", prop: "material" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择材质" },
                          model: {
                            value: _vm.itemForm.material,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "material", $$v)
                            },
                            expression: "itemForm.material"
                          }
                        },
                        _vm._l(_vm.materialList, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "批发进价",
                        prop: "wholesalePurchasePrice"
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入批发进价",
                          step: 0.01,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.wholesalePurchasePrice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.itemForm,
                              "wholesalePurchasePrice",
                              $$v
                            )
                          },
                          expression: "itemForm.wholesalePurchasePrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "寄售进价",
                        prop: "consignmentPurchasePrice"
                      }
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入寄售进价",
                          step: 0.01,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.consignmentPurchasePrice,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.itemForm,
                              "consignmentPurchasePrice",
                              $$v
                            )
                          },
                          expression: "itemForm.consignmentPurchasePrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "市场售价", prop: "marketPrice" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入市场售价",
                          step: 0.01,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.marketPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "marketPrice", $$v)
                          },
                          expression: "itemForm.marketPrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "寄售售价", prop: "consignmentPrice" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入寄售售价",
                          step: 0.01,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.consignmentPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "consignmentPrice", $$v)
                          },
                          expression: "itemForm.consignmentPrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批发售价", prop: "wholesalePrice" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入批发售价",
                          step: 0.01,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.wholesalePrice,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "wholesalePrice", $$v)
                          },
                          expression: "itemForm.wholesalePrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "医保编码", prop: "medCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入医保编码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.medCode,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "medCode", $$v)
                          },
                          expression: "itemForm.medCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "网采编码", prop: "netCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入网采编码",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.netCode,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "netCode", $$v)
                          },
                          expression: "itemForm.netCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "网采价", prop: "onlPrice" } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入网采价",
                          step: 0.01,
                          "controls-position": "right"
                        },
                        model: {
                          value: _vm.itemForm.onlPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "onlPrice", $$v)
                          },
                          expression: "itemForm.onlPrice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否集采类耗材", prop: "centralized" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.itemForm.centralized,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "centralized", $$v)
                            },
                            expression: "itemForm.centralized"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("否")
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("是")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "货币单位", prop: "monetaryUnit" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择货币单位"
                          },
                          model: {
                            value: _vm.itemForm.monetaryUnit,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "monetaryUnit", $$v)
                            },
                            expression: "itemForm.monetaryUnit"
                          }
                        },
                        _vm._l(_vm.monetaryUnitList, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "英文描述", prop: "genericNameEn" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入英文描述",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.genericNameEn,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "genericNameEn", $$v)
                          },
                          expression: "itemForm.genericNameEn"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通用名称", prop: "genericName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入中文描述（通用名称）",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.genericName,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "genericName", $$v)
                          },
                          expression: "itemForm.genericName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "效期产生策略", prop: "ep_month" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.itemForm.ep_month_check,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "ep_month_check", $$v)
                            },
                            expression: "itemForm.ep_month_check"
                          }
                        },
                        [_vm._v("按生产日期往后推算产生")]
                      ),
                      _c("el-input-number", {
                        staticStyle: { width: "100px", margin: "0 10px" },
                        attrs: {
                          "controls-position": "right",
                          min: 0,
                          disabled:
                            _vm.itemForm.ep_month_check === 0 ? true : false
                        },
                        model: {
                          value: _vm.itemForm.ep_month,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "ep_month", $$v)
                          },
                          expression: "itemForm.ep_month"
                        }
                      }),
                      _vm._v(" 个月 ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "效期预警策略", prop: "ep_day" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.itemForm.ep_day_check,
                                callback: function($$v) {
                                  _vm.$set(_vm.itemForm, "ep_day_check", $$v)
                                },
                                expression: "itemForm.ep_day_check"
                              }
                            },
                            [_vm._v("提前")]
                          ),
                          _vm._v(" "),
                          _c("el-input-number", {
                            staticStyle: { width: "100px", margin: "0 10px" },
                            attrs: { "controls-position": "right", min: 0 },
                            model: {
                              value: _vm.itemForm.ep_day,
                              callback: function($$v) {
                                _vm.$set(_vm.itemForm, "ep_day", $$v)
                              },
                              expression: "itemForm.ep_day"
                            }
                          }),
                          _vm._v(" 天 ")
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", align: "center" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.dialogClose }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "prevent-re-click",
                              rawName: "v-prevent-re-click"
                            }
                          ],
                          attrs: { size: "medium", type: "primary" },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v("提交")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("StorageSelectComponents", {
        ref: "StorageSelectComponents",
        on: { confirm: _vm.storageConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }