import { request, requestForMock, mock } from '@/api/_service.js';
import * as tools from '@/api/_tools.js';
export function listManufacturer(params) {
  return request({
    url: '/manu/getManufacturerList',
    method: 'get',
    params: params
  });
}
export function manufacturerList(params) {
  return request({
    url: '/manu/getManufacturerList',
    method: 'get',
    params: params
  });
}
export function findManufacturer(params) {
  return request({
    url: '/manu/findManufacturer',
    method: 'get',
    params: params
  });
}
export function createManufacturer(data) {
  return request({
    url: '/manu/createManufacturer',
    method: 'post',
    data: data
  });
}
export function updateManufacturer(data) {
  return request({
    url: '/manu/updateManufacturer',
    method: 'put',
    data: data
  });
}
export function deleteManufacturer(data) {
  return request({
    url: '/manu/deleteManufacturer',
    method: 'delete',
    data: data
  });
}
export function deleteManufacturerByIds(data) {
  return request({
    url: '/manu/deleteManufacturerByIds',
    method: 'delete',
    data: data
  });
}
export function downloadManuTemplate(params) {
  return request({
    url: '/manu/getManuTemplate1',
    method: 'get',
    params: params,
    responseType: 'blob'
  });
}