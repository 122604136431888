var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存管理")]),
              _c("el-breadcrumb-item", [_vm._v("库存预警")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between"
                }
              },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      inline: true,
                      model: _vm.pageForm,
                      size: "small",
                      "label-suffix": ":"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: "请选择",
                              multiple: "",
                              "collapse-tags": "",
                              filterable: "",
                              clearable: ""
                            },
                            on: { change: _vm.warehouseChange },
                            model: {
                              value: _vm.pageForm.warehouseIds,
                              callback: function($$v) {
                                _vm.$set(_vm.pageForm, "warehouseIds", $$v)
                              },
                              expression: "pageForm.warehouseIds"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "产品主码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品主码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.barCode,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "barCode", $$v)
                            },
                            expression: "pageForm.barCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "物资名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入物资名称",
                            clearable: ""
                          },
                          model: {
                            value: _vm.pageForm.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.pageForm, "productName", $$v)
                            },
                            expression: "pageForm.productName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.searchSubmit }
                          },
                          [_vm._v("查询")]
                        ),
                        _c("el-button", { on: { click: _vm.dataExport } }, [
                          _vm._v("导出")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "min-width": "110px" },
                    attrs: { align: "right" }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addWarning()
                          }
                        }
                      },
                      [_vm._v("添加预警产品")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c(
              "el-button",
              {
                attrs: { type: "danger", size: "mini" },
                on: {
                  click: function($event) {
                    return _vm.batchDelete()
                  }
                }
              },
              [_vm._v("批量删除")]
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasSelection: true,
            hasIndex: true,
            pagination: _vm.pageForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.tableData
          },
          scopedSlots: _vm._u([
            {
              key: "highInventory",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("el-input-number", {
                    attrs: { size: "mini", min: 0 },
                    on: {
                      change: function($event) {
                        return _vm.stockSave(row)
                      }
                    },
                    model: {
                      value: row.highInventory,
                      callback: function($$v) {
                        _vm.$set(row, "highInventory", $$v)
                      },
                      expression: "row.highInventory"
                    }
                  })
                ]
              }
            },
            {
              key: "lowInventory",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("el-input-number", {
                    attrs: { size: "mini", min: 0 },
                    on: {
                      change: function($event) {
                        return _vm.stockSave(row)
                      }
                    },
                    model: {
                      value: row.lowInventory,
                      callback: function($$v) {
                        _vm.$set(row, "lowInventory", $$v)
                      },
                      expression: "row.lowInventory"
                    }
                  })
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.stockRemove(row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.pageForm.total,
              "current-page": _vm.pageForm.page,
              "page-size": _vm.pageForm.pageSize
            },
            on: {
              "current-change": _vm.pageChange,
              "size-change": _vm.pageSizeChange
            }
          })
        ],
        1
      ),
      _c("WarningComponent", {
        ref: "WarningComponentRef",
        on: { reload: _vm.loadData }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }