var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "stockForm",
          attrs: {
            inline: true,
            "label-width": "80px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.stockFormSubmit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.stockForm,
            callback: function($$v) {
              _vm.stockForm = $$v
            },
            expression: "stockForm"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品主码" } },
            [
              _c("el-input", {
                staticClass: "bar-input",
                attrs: { placeholder: "产品主码", clearable: "" },
                model: {
                  value: _vm.stockForm.barCode,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "barCode", $$v)
                  },
                  expression: "stockForm.barCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品副码" } },
            [
              _c("el-input", {
                ref: "barcodeInput",
                staticClass: "bar-input",
                attrs: { placeholder: "产品副码", clearable: "" },
                model: {
                  value: _vm.scanBarCode,
                  callback: function($$v) {
                    _vm.scanBarCode = $$v
                  },
                  expression: "scanBarCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "产品编号", clearable: "" },
                model: {
                  value: _vm.stockForm.productNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "productNumber", $$v)
                  },
                  expression: "stockForm.productNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "产品名称" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "产品名称", clearable: "" },
                model: {
                  value: _vm.stockForm.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "productName", $$v)
                  },
                  expression: "stockForm.productName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "规格型号" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "规格型号", clearable: "" },
                model: {
                  value: _vm.stockForm.specificationModel,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "specificationModel", $$v)
                  },
                  expression: "stockForm.specificationModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand && _vm.issueType !== 3,
                  expression: "searchFormExpand && issueType!==3"
                }
              ],
              attrs: { label: "归属公司" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    filterable: ""
                  },
                  model: {
                    value: _vm.stockForm.subsidiaryId,
                    callback: function($$v) {
                      _vm.$set(_vm.stockForm, "subsidiaryId", $$v)
                    },
                    expression: "stockForm.subsidiaryId"
                  }
                },
                _vm._l(_vm.subsidiaryList, function(item) {
                  return _c("el-option", {
                    key: "subsidiaryId" + item.ID,
                    attrs: {
                      label: item.companyName,
                      value: item.ID,
                      disabled: !item.isValid
                    }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "品牌" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择品牌",
                    clearable: "",
                    filterable: ""
                  },
                  model: {
                    value: _vm.stockForm.brandId,
                    callback: function($$v) {
                      _vm.$set(_vm.stockForm, "brandId", $$v)
                    },
                    expression: "stockForm.brandId"
                  }
                },
                _vm._l(_vm.brandList, function(item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand && _vm.issueType !== 3,
                  expression: "searchFormExpand && issueType!==3"
                }
              ],
              attrs: { label: "生产批号" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "生产批号", clearable: "" },
                model: {
                  value: _vm.stockForm.batch_number,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "batch_number", $$v)
                  },
                  expression: "stockForm.batch_number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand && _vm.issueType !== 3,
                  expression: "searchFormExpand&&issueType!==3"
                }
              ],
              attrs: { label: "序列号" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "序列号", clearable: "" },
                model: {
                  value: _vm.stockForm.trackingCode,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "trackingCode", $$v)
                  },
                  expression: "stockForm.trackingCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "注册证号" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "注册证号", clearable: "" },
                model: {
                  value: _vm.stockForm.registrationNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "registrationNumber", $$v)
                  },
                  expression: "stockForm.registrationNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.searchFormExpand,
                  expression: "searchFormExpand"
                }
              ],
              attrs: { label: "产品状态" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择状态", clearable: "" },
                  model: {
                    value: _vm.stockForm.state,
                    callback: function($$v) {
                      _vm.$set(_vm.stockForm, "state", $$v)
                    },
                    expression: "stockForm.state"
                  }
                },
                _vm._l(_vm.stateList, function(item) {
                  return _c("el-option", {
                    key: item.ID,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", "label-width": "0" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "经营范围匹配",
                  "inactive-text": "",
                  "active-value": 1,
                  "inactive-value": 0
                },
                model: {
                  value: _vm.stockForm.natureBusiness,
                  callback: function($$v) {
                    _vm.$set(_vm.stockForm, "natureBusiness", $$v)
                  },
                  expression: "stockForm.natureBusiness"
                }
              }),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "true-label": 1, "false-label": 0 },
                  model: {
                    value: _vm.stockForm.showEmpty,
                    callback: function($$v) {
                      _vm.$set(_vm.stockForm, "showEmpty", $$v)
                    },
                    expression: "stockForm.showEmpty"
                  }
                },
                [_vm._v("只显示可用数>0的产品")]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", "label-width": "0" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.stockFormSubmit }
                },
                [_vm._v("查 询")]
              ),
              _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.searchFormExpandChange }
                },
                [
                  _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                  _c("i", {
                    class: _vm.searchFormExpand
                      ? "el-icon-arrow-up el-icon--right"
                      : "el-icon-arrow-down el-icon--right"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.stockLoading,
              expression: "stockLoading"
            }
          ],
          ref: "stockListTable",
          attrs: {
            id: "stockListTable",
            data: _vm.stockList,
            border: "",
            size: "mini",
            "max-height": "270px",
            "header-cell-style": {
              background: "#f5f7fa",
              borderColor: "#ebeef5",
              color: "#333"
            },
            "row-class-name": _vm.rowClassName
          },
          on: {
            "selection-change": _vm.selectionChange,
            "row-click": _vm.rowClick,
            "row-dblclick": _vm.rowDbClick
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "40", fixed: "" }
          }),
          _c("el-table-column", {
            attrs: {
              type: "index",
              index: function(index) {
                return _vm.indexMethod(index, _vm.stockForm)
              },
              label: "序号",
              width: "80",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.expireDays <= 90 && scope.row.expireDays > 0
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content:
                                "该产品还有" + scope.row.expireDays + "天过期",
                              placement: "top"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "align-items": "center",
                                  "justify-content": "center",
                                  position: "relative"
                                }
                              },
                              [
                                _c("d2-icon-svg", {
                                  staticStyle: {
                                    width: "16px",
                                    height: "14px",
                                    position: "absolute",
                                    left: "0"
                                  },
                                  attrs: { name: "warning-yellow" }
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.indexMethod(
                                        scope.$index,
                                        _vm.stockForm
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.expireDays <= 0
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content:
                                "该产品已过期" +
                                Math.abs(scope.row.expireDays) +
                                "天",
                              placement: "top"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row",
                                  "align-items": "center",
                                  "justify-content": "center",
                                  position: "relative"
                                }
                              },
                              [
                                _c("d2-icon-svg", {
                                  staticStyle: {
                                    width: "16px",
                                    height: "14px",
                                    position: "absolute",
                                    left: "0"
                                  },
                                  attrs: { name: "warning-red" }
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.indexMethod(
                                        scope.$index,
                                        _vm.stockForm
                                      )
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    scope.row.expireDays > 90
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.indexMethod(scope.$index, _vm.stockForm))
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm.issueType !== 3
            ? [
                _c("el-table-column", {
                  attrs: {
                    width: "100",
                    property: "companyName",
                    label: "归属公司",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                })
              ]
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              width: "150",
              property: "productNumber",
              label: "产品编号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              property: "productName",
              label: "产品名称",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "150",
              property: "specificationModel",
              label: "规格型号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "normalQuantity",
              label: "可用数量",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "150",
              property: "barCode",
              label: "UDI-DI",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "100",
              property: "brandName",
              label: "品牌",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "200",
              property: "registrationNumber",
              label: "注册证号",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "80",
              property: "unit",
              label: "单位",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              width: "80",
              property: "state",
              label: "产品状态",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.state === 1
                      ? _c("div", { staticClass: "status-success" }, [
                          _c("div", { staticClass: "dot" })
                        ])
                      : _c("div", { staticClass: "status-danger" }, [
                          _c("div", { staticClass: "dot" })
                        ])
                  ]
                }
              }
            ])
          }),
          _vm.sellProcessMode === 0
            ? [
                _c("el-table-column", {
                  attrs: {
                    width: "120",
                    property: "sellUnitPrice",
                    label: "销售单价",
                    align: "center",
                    fixed: "right"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                min: 0,
                                precision: 2,
                                controls: false
                              },
                              model: {
                                value: row.sellUnitPrice,
                                callback: function($$v) {
                                  _vm.$set(row, "sellUnitPrice", $$v)
                                },
                                expression: "row.sellUnitPrice"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1041904500
                  )
                })
              ]
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              width: "120",
              property: "deliveryQuantity",
              label: "数量",
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c("el-input-number", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "mini",
                        min: 0,
                        precision: 0,
                        controls: false
                      },
                      on: {
                        change: function($event) {
                          return _vm.checkSelect(row)
                        }
                      },
                      nativeOn: {
                        dblclick: function($event) {
                          $event.stopPropagation()
                        }
                      },
                      model: {
                        value: row.deliveryQuantity,
                        callback: function($$v) {
                          _vm.$set(row, "deliveryQuantity", _vm._n($$v))
                        },
                        expression: "row.deliveryQuantity"
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { margin: "10px 0" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              "page-sizes": [5, 10, 20, 50, 100],
              total: _vm.stockForm.total,
              "current-page": _vm.stockForm.page,
              "page-size": _vm.stockForm.pageSize
            },
            on: {
              "current-change": _vm.stockListPageChange,
              "size-change": _vm.stockListSizeChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "flex-end"
          }
        },
        [
          _c("el-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            {
              directives: [
                { name: "prevent-re-click", rawName: "v-prevent-re-click" }
              ],
              ref: "addButton",
              attrs: { type: "primary", "native-type": "submit" },
              on: { click: _vm.stockAddToList }
            },
            [_vm._v("添加")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }