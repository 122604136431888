import _objectSpread from "D:/wwwroot/ERP\u524D\u7AEF/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var meta = {
  auth: true
};
export default {
  path: '/stock',
  name: 'stock',
  meta: meta,
  component: layoutHeaderAside,
  children: [{
    path: 'summary/index',
    name: 'stock-summary-index',
    component: _import('stock/summary/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存总览'
    })
  }, {
    path: 'detail/index',
    name: 'stock-detail-index',
    component: _import('stock/detail/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存明细'
    }),
    props: true
  }, {
    path: 'detail/virtual',
    name: 'stock-detail-virtual',
    component: _import('stock/detail/virtual'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '虚拟库存明细'
    }),
    props: true
  }, {
    path: 'detail/from/:id',
    name: 'stock-detail-from',
    component: _import('stock/detail/from'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '产品追溯'
    }),
    props: true
  }, {
    path: 'summary/batch',
    name: 'stock-summary-batch',
    component: _import('stock/summary/batch'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存汇总（批号）'
    })
  }, {
    path: 'summary/special',
    name: 'stock-summary-special',
    component: _import('stock/summary/special'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存汇总（规格）'
    })
  }, {
    path: 'inventory/price',
    name: 'stock-inventory-price',
    component: _import('stock/inventory/price'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存盘点（价格）'
    })
  }, {
    path: 'inventory/price/detail/:id/:action',
    name: 'stock-inventory-price-detail',
    component: _import('stock/inventory/price_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '盘存详情'
    }),
    props: true
  }, {
    path: 'inventory/add',
    name: 'stock-inventory-add',
    component: _import('stock/inventory/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增盘存单'
    })
  }, {
    path: 'packages/index',
    name: 'stock-packages-index',
    component: _import('stock/packages/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '耗材包'
    })
  }, {
    path: 'consum_group/index',
    name: 'stock-consum_group-index',
    component: _import('stock/consum_group/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '耗材组套'
    })
  }, {
    path: 'consum_group/index_new',
    name: 'stock-consum_group-indexnew',
    component: _import('stock/consum_group/index_new'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '耗材组套(新)'
    })
  }, {
    path: 'box/transfer',
    name: 'stock-box-transfer',
    component: _import('stock/box/transfer'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '螺钉盒转移'
    })
  }, {
    path: 'trace/index',
    name: 'stock-trace-index',
    component: _import('stock/trace/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '锁定追踪'
    })
  }, {
    path: 'warning/validity',
    name: 'stock-warning-validity',
    component: _import('stock/warning/validity'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '效期预警'
    })
  }, {
    path: 'warning/stock',
    name: 'stock-warning-stock',
    component: _import('stock/warning/stock'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '库存预警'
    })
  }, {
    path: 'warning/nonmoving',
    name: 'stock-warning-nonmoving',
    component: _import('stock/warning/nonmoving'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '不动销产品预警'
    }),
    props: true
  }, {
    path: 'inwarehouse/accept/index',
    name: 'stock-inwarehouse-accept-index',
    component: _import('stock/inwarehouse/accept/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库验收记录'
    })
  }, {
    path: 'inwarehouse/procurement/index',
    name: 'stock-inwarehouse-procurement-index',
    component: _import('stock/inwarehouse/procurement/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购入库'
    })
  }, {
    path: 'inwarehouse/procurement/index/detail/:id//:action',
    name: 'stock-inwarehouse-procurement-index-detail',
    component: _import('stock/inwarehouse/procurement/index_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库单详情'
    }),
    props: true
  }, {
    path: 'inwarehouse/opening/index',
    name: 'stock-inwarehouse-opening-index',
    component: _import('stock/inwarehouse/opening/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '期初入库'
    })
  }, {
    path: 'inwarehouse/opening/index/detail/:id/:action',
    name: 'stock-inwarehouse-opening-index-detail',
    component: _import('stock/inwarehouse/opening/index_detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库单详情'
    }),
    props: true
  }, {
    path: 'inwarehouse/check',
    name: 'stock-inwarehouse-check-index',
    component: _import('stock/inwarehouse/check/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库审核'
    })
  }, {
    path: 'inwarehouse/check/detail/:id',
    name: 'stock-inwarehouse-check-detail',
    component: _import('stock/inwarehouse/check/detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库单详情'
    }),
    props: true
  }, {
    path: 'inwarehouse/salesreturn/index',
    name: 'stock-inwarehouse-salesreturn-index',
    component: _import('stock/inwarehouse/salesreturn/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售退货入库'
    })
  }, {
    path: 'inwarehouse/salesreturn/index/detail/:id/:action',
    name: 'stock-inwarehouse-salesreturn-index-detail',
    component: _import('stock/inwarehouse/salesreturn/index_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售退货入库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/noreturn/index',
    name: 'stock-outwarehouse-noreturn-index',
    component: _import('stock/outwarehouse/noreturn/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '未回库列表'
    })
  }, {
    path: 'outwarehouse/youtaiout/index',
    name: 'stock-outwarehouse-youtaiout-index',
    component: _import('stock/outwarehouse/youtaiout/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '有台出库'
    })
  }, {
    path: 'outwarehouse/youtaiout/index/detail/:id/:action',
    name: 'stock-outwarehouse-youtaiout-index-detail',
    component: _import('stock/outwarehouse/youtaiout/index_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/youtaiout/create',
    name: 'stock-outwarehouse-youtaiout-create',
    component: _import('stock/outwarehouse/youtaiout/create/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '创建出库单'
    })
  }, {
    path: 'outwarehouse/directsales/index',
    name: 'stock-outwarehouse-directsales-index',
    component: _import('stock/outwarehouse/directsales/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '直销出库'
    })
  }, {
    path: 'outwarehouse/directsales/create',
    name: 'stock-outwarehouse-directsales-create',
    component: _import('stock/outwarehouse/directsales/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增直销出库单'
    }),
    props: true
  }, {
    path: 'outwarehouse/directsales/detail/:id',
    name: 'stock-outwarehouse-directsales-detail',
    component: _import('stock/outwarehouse/directsales/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '直销出库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/consignment/index',
    name: 'stock-outwarehouse-consignment-index',
    component: _import('stock/outwarehouse/consignment/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '临调出库'
    })
  }, {
    path: 'outwarehouse/consignment/index/detail/:id/:action',
    name: 'stock-outwarehouse-consignment-index-detail',
    component: _import('stock/outwarehouse/consignment/index_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/agent/index',
    name: 'stock-outwarehouse-agent-index',
    component: _import('stock/outwarehouse/agent/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '平台寄售出库'
    }),
    props: true
  }, {
    path: 'outwarehouse/agent/create',
    name: 'stock-outwarehouse-agent-create',
    component: _import('stock/outwarehouse/agent/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增寄售出库单'
    }),
    props: true
  }, {
    path: 'outwarehouse/agent/detail/:id',
    name: 'stock-outwarehouse-agent-detail',
    component: _import('stock/outwarehouse/agent/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售出库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/sales/index',
    name: 'stock-outwarehouse-sales-index',
    component: _import('stock/outwarehouse/sales/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '销售出库'
    })
  }, {
    path: 'outwarehouse/sales/index/detail/:id/:action',
    name: 'stock-outwarehouse-sales-index-detail',
    component: _import('stock/outwarehouse/sales/index_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/restock/index',
    name: 'stock-outwarehouse-restock-index',
    component: _import('stock/outwarehouse/restock/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '补货出库'
    })
  }, {
    path: 'outwarehouse/restock/detail/:id',
    name: 'stock-outwarehouse-restock-detail',
    component: _import('stock/outwarehouse/restock/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/restock/add',
    name: 'stock-outwarehouse-restock-add',
    component: _import('stock/outwarehouse/restock/add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/accept/index',
    name: 'stock-outwarehouse-accept-index',
    component: _import('stock/outwarehouse/accept/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '出库检验记录'
    }),
    props: true
  }, {
    path: 'allocate/outwarehouse/index',
    name: 'stock-allocate-outwarehouse-index',
    component: _import('stock/allocate/outwarehouse/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '调拨出库'
    })
  }, {
    path: 'allocate/outwarehouse/create',
    name: 'stock-allocate-outwarehouse-create',
    component: _import('stock/allocate/outwarehouse/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增调拨出库单'
    }),
    props: true
  }, {
    path: 'allocate/outwarehouse/detail/:id',
    name: 'stock-allocate-outwarehouse-detail',
    component: _import('stock/allocate/outwarehouse/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '调拨出库单详情'
    }),
    props: true
  }, {
    path: 'allocate/inwarehouse/index',
    name: 'stock-allocate-inwarehouse-index',
    component: _import('stock/allocate/inwarehouse/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '调拨入库'
    })
  }, {
    path: 'allocate/inwarehouse/create',
    name: 'stock-allocate-inwarehouse-create',
    component: _import('stock/allocate/inwarehouse/create'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增调拨入库单'
    }),
    props: true
  }, {
    path: 'allocate/inwarehouse/detail/:id',
    name: 'stock-allocate-inwarehouse-detail',
    component: _import('stock/allocate/inwarehouse/detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '调拨入库单详情'
    }),
    props: true
  }, {
    path: 'inwarehouse/consignment/index',
    name: 'stock-inwarehouse-consignment-index',
    component: _import('stock/inwarehouse/consignment/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售入库'
    })
  }, {
    path: 'inwarehouse/consignment/index/detail/:id/:action',
    name: 'stock-inwarehouse-consignment-index-detail',
    component: _import('stock/inwarehouse/consignment/index_detail.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '入库单详情'
    }),
    props: true
  }, {
    path: 'inwarehouse/consignment/return/index',
    name: 'stock-inwarehouse-consignment-return-index',
    component: _import('stock/inwarehouse/consignment/return/index.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售退货'
    })
  }, {
    path: 'inwarehouse/consignment/return/create',
    name: 'stock-inwarehouse-consignment-return-create',
    component: _import('stock/inwarehouse/consignment/return/create.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增寄售退货单'
    }),
    props: true
  }, {
    path: 'inwarehouse/consignment/return/detail/:id',
    name: 'stock-inwarehouse-consignment-return-detail',
    component: _import('stock/inwarehouse/consignment/return/view.vue'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '寄售退货单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/procurement/return',
    name: 'stock-outwarehouse-procurement-return',
    component: _import('stock/outwarehouse/procurement/return'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购退货出库'
    })
  }, {
    path: 'outwarehouse/procurement/return/add',
    name: 'stock-outwarehouse-procurement-return-add',
    component: _import('stock/outwarehouse/procurement/return_add'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '新增采购退货出库单'
    }),
    props: true
  }, {
    path: 'outwarehouse/procurement/return/edit/:id',
    name: 'stock-outwarehouse-procurement-return-edit',
    component: _import('stock/outwarehouse/procurement/return_edit'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购退货出库单详情'
    }),
    props: true
  }, {
    path: 'outwarehouse/procurement/return/detail/:id/:deliveryOrderNo/:action',
    name: 'stock-outwarehouse-procurement-return-detail',
    component: _import('stock/outwarehouse/procurement/return_detail'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '采购退货出库单详情'
    }),
    props: true
  }, {
    path: 'allocate/query/index',
    name: 'stock-allocate-query-index',
    component: _import('stock/allocate/query/index'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '调拨查询'
    })
  }, {
    path: 'detail/test',
    name: 'stock-detail-test',
    component: _import('stock/detail/test'),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      cache: true,
      title: '调拨查询'
    })
  }]
};