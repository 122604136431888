var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("入库管理")]),
              _c("el-breadcrumb-item", [_vm._v("寄售入库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "入库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "新增") +
                    "）"
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.form.inWarehouseStatus > 1,
              "label-suffix": ":",
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "仓库", prop: "warehouseId" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "warehouseId",
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.warehouseId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "warehouseId", $$v)
                              },
                              expression: "form.warehouseId"
                            }
                          },
                          _vm._l(_vm.warehouseList, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: "warehouseId" + item.ID,
                                attrs: {
                                  label: item.name,
                                  value: item.ID,
                                  disabled: item.status === 0
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "space-between"
                                    }
                                  },
                                  [
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.status === 0 ? true : false,
                                            expression:
                                              "item.status===0?true:false"
                                          }
                                        ],
                                        staticClass: "text-danger"
                                      },
                                      [_vm._v("(无效)")]
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商", prop: "manufacturerId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                              disabled: _vm.detailList.length > 0 ? true : false
                            },
                            model: {
                              value: _vm.form.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "manufacturerId", $$v)
                              },
                              expression: "form.manufacturerId"
                            }
                          },
                          _vm._l(_vm.manufacturerList, function(item) {
                            return _c("el-option", {
                              key: "manufacturerId" + item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库人", prop: "inWarehouseUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.inWarehouseUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "inWarehouseUid", $$v)
                              },
                              expression: "form.inWarehouseUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "inWarehouseUid" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库时间", prop: "inWarehouseTime" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期时间"
                          },
                          model: {
                            value: _vm.form.inWarehouseTime,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "inWarehouseTime", $$v)
                            },
                            expression: "form.inWarehouseTime"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "归属公司", prop: "subsidiaryId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择"
                            },
                            model: {
                              value: _vm.form.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "subsidiaryId", $$v)
                              },
                              expression: "form.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: {
                                label: item.companyName,
                                value: item.ID,
                                disabled: item.isValid === 0
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 18 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "备注",
                            maxlength: "500",
                            "suffix-icon": "el-icon-edit",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.selectSpecs }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.inWarehouseStatus !== 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.importFromOutOrderNumber()
                      }
                    }
                  },
                  [_vm._v("平台寄售出库单号导入")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.importFromExcel()
                      }
                    }
                  },
                  [_vm._v("从EXCEL中导入")]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "font-size": "14px"
                }
              },
              [
                _c("div", [
                  _vm.activeTabId === 1
                    ? _c("span", [
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(" 总金额（不含税）："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.totalAmountExclusive()))]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(" 总金额（含税）："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.totalAmount()))]
                            )
                          ]
                        ),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _vm._v(" 税额合计："),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00",
                                  "font-weight": "bold"
                                }
                              },
                              [_vm._v(_vm._s(_vm.totalAmountTax()))]
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" 总数量（"),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  ),
                  _vm._v("） ")
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: !_vm.form.id
                        },
                        on: { click: _vm.orderPrint }
                      },
                      [
                        _c("i", { staticClass: "fa fa-print" }),
                        _vm._v(" 打印入库单")
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanImportBox,
                expression: "showScanImportBox"
              }
            ]
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                ref: "importFormRef",
                attrs: {
                  model: _vm.importForm,
                  rules: _vm.importFormRules,
                  "label-width": "0px",
                  "show-message": false
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.importFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "deliveryOrderNo", "label-width": "0px" } },
                  [
                    _c("el-input", {
                      ref: "importInputBoxRef",
                      staticClass: "import-input-box",
                      attrs: {
                        placeholder: "请输入、扫描出库单号",
                        clearable: ""
                      },
                      model: {
                        value: _vm.importForm.deliveryOrderNo,
                        callback: function($$v) {
                          _vm.$set(_vm.importForm, "deliveryOrderNo", $$v)
                        },
                        expression: "importForm.deliveryOrderNo"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "mini",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _vm.returnList.length > 0
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "tab-list-grey",
                      staticStyle: { margin: "0" }
                    },
                    [
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 1
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(1)
                            }
                          }
                        },
                        [_vm._v("入库单详情")]
                      ),
                      _c(
                        "div",
                        {
                          class:
                            _vm.activeTabId === 2
                              ? "tab-item hover"
                              : "tab-item",
                          on: {
                            click: function($event) {
                              return _vm.tabChange(2)
                            }
                          }
                        },
                        [_vm._v("退货详情")]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabId === 1,
                    expression: "activeTabId===1"
                  }
                ]
              },
              [
                _c("YTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  ref: "dragTableRef",
                  attrs: {
                    hasIndex: true,
                    hasPagination: true,
                    pagination: _vm.detailListForm,
                    rowClassName: _vm.rowClassName,
                    "route-name": _vm.$route.name + "detail-list",
                    columns: _vm.detailColumns,
                    data: _vm.detailListForm.data.slice(
                      (_vm.detailListForm.page - 1) *
                        _vm.detailListForm.pageSize,
                      _vm.detailListForm.page * _vm.detailListForm.pageSize
                    )
                  },
                  on: {
                    "row-click": _vm.detailRowClick,
                    pageChange: _vm.detailPageChange,
                    sizeChange: _vm.detailSizeChange
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "inWarehouseQuantity",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "inWarehouseQuantity" + index,
                                  attrs: {
                                    prop:
                                      "data." + index + ".inWarehouseQuantity",
                                    rules:
                                      _vm.detailListFormRules
                                        .inWarehouseQuantity
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    ref: "inWarehouseQuantity-" + index,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      placeholder: "入库数量",
                                      precision: 0,
                                      controls: false
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "afterTaxPrice-" + index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.inWarehouseQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row,
                                          "inWarehouseQuantity",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "row.inWarehouseQuantity"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.inWarehouseQuantity))
                              ])
                        ]
                      }
                    },
                    {
                      key: "purchaseAmountTax",
                      fn: function(scope) {
                        return [_vm._v(_vm._s(_vm.sumRowAmount(scope.row)))]
                      }
                    },
                    {
                      key: "purchaseAmount",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(_vm.sumRowAmountTaxExclusive(scope.row))
                          )
                        ]
                      }
                    },
                    {
                      key: "afterTaxPrice",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c("el-input-number", {
                                ref: "afterTaxPrice-" + index,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  precision: 2,
                                  controls: false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                model: {
                                  value: row.afterTaxPrice,
                                  callback: function($$v) {
                                    _vm.$set(row, "afterTaxPrice", $$v)
                                  },
                                  expression: "row.afterTaxPrice"
                                }
                              })
                            : _c("span", [_vm._v(_vm._s(row.afterTaxPrice))])
                        ]
                      }
                    },
                    {
                      key: "taxRate",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "data." + index + ".taxRate",
                                rules: _vm.detailListFormRules.taxRate
                              }
                            },
                            [
                              _vm.form.inWarehouseStatus === 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("el-input-number", {
                                        ref: "taxRate-" + index,
                                        attrs: {
                                          size: "mini",
                                          precision: 2,
                                          controls: false,
                                          min: 0,
                                          max: 100
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.detailSaveOne(row)
                                          }
                                        },
                                        model: {
                                          value: row.taxRate,
                                          callback: function($$v) {
                                            _vm.$set(row, "taxRate", $$v)
                                          },
                                          expression: "row.taxRate"
                                        }
                                      }),
                                      _c(
                                        "span",
                                        { staticStyle: { padding: "0 5px" } },
                                        [_vm._v("%")]
                                      )
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.taxRate == undefined ||
                                          row.taxRate == null
                                          ? 0
                                          : row.taxRate
                                      ) + "%"
                                    )
                                  ])
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "taxAmount",
                      fn: function(scope) {
                        return [
                          _vm._v(_vm._s(_vm.calculateTaxAmount(scope.row)))
                        ]
                      }
                    },
                    {
                      key: "purchaseUnitPriceNoTax",
                      fn: function(scope) {
                        return [
                          _vm._v(
                            _vm._s(_vm.calculateTaxPurchaseUnitPrice(scope.row))
                          )
                        ]
                      }
                    },
                    {
                      key: "batchNumber",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "batchNumber" + index,
                                  attrs: {
                                    prop: "data." + index + ".batchNumber",
                                    rules: _vm.detailListFormRules.batchNumber
                                  }
                                },
                                [
                                  _c("el-input", {
                                    ref: "batchNumber-" + index,
                                    attrs: {
                                      size: "mini",
                                      placeholder: "生产批号"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "trackingCode-" + index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.batchNumber,
                                      callback: function($$v) {
                                        _vm.$set(row, "batchNumber", $$v)
                                      },
                                      expression: "row.batchNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.batchNumber))])
                        ]
                      }
                    },
                    {
                      key: "trackingCode",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "trackingCode" + index,
                                  attrs: {
                                    prop: "data." + index + ".trackingCode"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    ref: "trackingCode-" + index,
                                    attrs: {
                                      size: "mini",
                                      placeholder: "序列号"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "manufacturerDate-" + index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.trackingCode,
                                      callback: function($$v) {
                                        _vm.$set(row, "trackingCode", $$v)
                                      },
                                      expression: "row.trackingCode"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.trackingCode))])
                        ]
                      }
                    },
                    {
                      key: "manufacturerDate",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "manufacturerDate" + index,
                                  attrs: {
                                    prop: "data." + index + ".manufacturerDate",
                                    rules:
                                      _vm.detailListFormRules.manufacturerDate
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    ref: "manufacturerDatePicker-" + index,
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "-100",
                                      width: "100%"
                                    },
                                    attrs: {
                                      id: "manufacturerDatePicker-" + index,
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      editable: false,
                                      "append-to-body": true,
                                      type: "date"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.manufacturerDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "manufacturerDate", $$v)
                                      },
                                      expression: "row.manufacturerDate"
                                    }
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      ref: "manufacturerDate-" + index,
                                      staticClass: "date-input",
                                      attrs: { size: "mini" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return function(event) {
                                            return _vm.nextFocus(
                                              "validityDate-" + index,
                                              event
                                            )
                                          }.apply(null, arguments)
                                        }
                                      },
                                      model: {
                                        value: row.manufacturerDate,
                                        callback: function($$v) {
                                          _vm.$set(row, "manufacturerDate", $$v)
                                        },
                                        expression: "row.manufacturerDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-date",
                                        attrs: { slot: "append" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDatePicker(
                                              "manufacturerDatePicker-" + index
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.manufacturerDate))])
                        ]
                      }
                    },
                    {
                      key: "validityDate",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-date-picker", {
                                    ref: "validityDatePicker-" + index,
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "-100",
                                      width: "100%"
                                    },
                                    attrs: {
                                      id: "validityDatePicker-" + index,
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      editable: false,
                                      type: "date"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.validityDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "validityDate", $$v)
                                      },
                                      expression: "row.validityDate"
                                    }
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      ref: "validityDate-" + index,
                                      staticClass: "date-input",
                                      attrs: { size: "mini" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return function(event) {
                                            return _vm.nextFocus(
                                              "sterilizationBatchNumber-" +
                                                index,
                                              event
                                            )
                                          }.apply(null, arguments)
                                        }
                                      },
                                      model: {
                                        value: row.validityDate,
                                        callback: function($$v) {
                                          _vm.$set(row, "validityDate", $$v)
                                        },
                                        expression: "row.validityDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-date",
                                        attrs: { slot: "append" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDatePicker(
                                              "validityDatePicker-" + index
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.validityDate))])
                        ]
                      }
                    },
                    {
                      key: "shelfName",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "shelfName" + index,
                                  attrs: {
                                    prop: "data." + index + ".shelfName"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "date-input",
                                      staticStyle: { width: "100%" },
                                      attrs: { size: "mini", readonly: true },
                                      model: {
                                        value: row.shelfName,
                                        callback: function($$v) {
                                          _vm.$set(row, "shelfName", $$v)
                                        },
                                        expression: "row.shelfName"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-caret-bottom"
                                        },
                                        on: {
                                          click: function(e) {
                                            return _vm.showShelf(e, row)
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.shelfName))])
                        ]
                      }
                    },
                    {
                      key: "locationNumber",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "locationNumber" + index,
                                  attrs: {
                                    prop: "data." + index + ".locationNumber"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "date-input",
                                      staticStyle: { width: "100%" },
                                      attrs: { size: "mini", readonly: true },
                                      model: {
                                        value: row.locationNumber,
                                        callback: function($$v) {
                                          _vm.$set(row, "locationNumber", $$v)
                                        },
                                        expression: "row.locationNumber"
                                      }
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-caret-bottom"
                                        },
                                        on: {
                                          click: function(e) {
                                            return _vm.showLocation(e, row)
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.locationNumber))])
                        ]
                      }
                    },
                    {
                      key: "sterilizationBatchNumber",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "sterilizationBatchNumber" + index,
                                  attrs: {
                                    prop:
                                      "data." +
                                      index +
                                      ".sterilizationBatchNumber"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    ref: "sterilizationBatchNumber-" + index,
                                    staticStyle: { width: "100%" },
                                    attrs: { size: "mini" },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "sterilizationDate-" + index,
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.sterilizationBatchNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          row,
                                          "sterilizationBatchNumber",
                                          $$v
                                        )
                                      },
                                      expression: "row.sterilizationBatchNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.sterilizationBatchNumber))
                              ])
                        ]
                      }
                    },
                    {
                      key: "sterilizationDate",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus == 1
                            ? _c(
                                "el-form-item",
                                [
                                  _c("el-date-picker", {
                                    ref: "sterilizationDatePicker-" + index,
                                    staticStyle: {
                                      position: "absolute",
                                      "z-index": "-100"
                                    },
                                    attrs: {
                                      id: "sterilizationDatePicker-" + index,
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd",
                                      editable: false,
                                      type: "date"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    model: {
                                      value: row.sterilizationDate,
                                      callback: function($$v) {
                                        _vm.$set(row, "sterilizationDate", $$v)
                                      },
                                      expression: "row.sterilizationDate"
                                    }
                                  }),
                                  _c(
                                    "el-input",
                                    {
                                      ref: "sterilizationDate-" + index,
                                      staticClass: "date-input",
                                      attrs: { size: "mini" },
                                      on: {
                                        change: function($event) {
                                          return _vm.detailSaveOne(row)
                                        }
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return function(event) {
                                            return _vm.nextFocus(
                                              "remark-" + index,
                                              event
                                            )
                                          }.apply(null, arguments)
                                        }
                                      },
                                      model: {
                                        value: row.sterilizationDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            row,
                                            "sterilizationDate",
                                            $$v
                                          )
                                        },
                                        expression: "row.sterilizationDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-date",
                                        attrs: { slot: "append" },
                                        on: {
                                          click: function($event) {
                                            return _vm.showDatePicker(
                                              "sterilizationDatePicker-" + index
                                            )
                                          }
                                        },
                                        slot: "append"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.sterilizationDate))
                              ])
                        ]
                      }
                    },
                    {
                      key: "remark",
                      fn: function(ref) {
                        var row = ref.row
                        var index = ref.index
                        return [
                          _vm.form.inWarehouseStatus === 1
                            ? _c(
                                "el-form-item",
                                {
                                  key: "remark" + index,
                                  attrs: { prop: "data." + index + ".remark" }
                                },
                                [
                                  _c("el-input", {
                                    ref: "remark-" + index,
                                    attrs: {
                                      size: "mini",
                                      placeholder: "备注"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.detailSaveOne(row)
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return function(event) {
                                          return _vm.nextFocus(
                                            "inWarehouseQuantity-" +
                                              (index + 1),
                                            event
                                          )
                                        }.apply(null, arguments)
                                      }
                                    },
                                    model: {
                                      value: row.remark,
                                      callback: function($$v) {
                                        _vm.$set(row, "remark", $$v)
                                      },
                                      expression: "row.remark"
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("span", [_vm._v(_vm._s(row.remark))])
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTabId === 2,
                    expression: "activeTabId===2"
                  }
                ]
              },
              [
                _c("YTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.detailLoading,
                      expression: "detailLoading"
                    }
                  ],
                  ref: "returnListTableRef",
                  attrs: {
                    hasIndex: true,
                    hasPagination: true,
                    pagination: _vm.returnForm,
                    rowClassName: _vm.rowClassName,
                    "route-name": _vm.$route.name + "detail-list",
                    columns: _vm.detailColumns,
                    data: _vm.returnList.slice(
                      (_vm.returnForm.page - 1) * _vm.returnForm.pageSize,
                      _vm.returnForm.page * _vm.returnForm.pageSize
                    )
                  }
                })
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "shelfNumberPopover",
                attrs: {
                  placement: "right-start",
                  width: "250",
                  trigger: "click"
                },
                model: {
                  value: _vm.shelfNumberVisible,
                  callback: function($$v) {
                    _vm.shelfNumberVisible = $$v
                  },
                  expression: "shelfNumberVisible"
                }
              },
              [
                _c("ShelfComponents", {
                  ref: "ShelfComponents",
                  on: { select: _vm.shelfSelect }
                })
              ],
              1
            ),
            _c(
              "el-popover",
              {
                ref: "locationNumberPopover",
                attrs: {
                  placement: "right-start",
                  width: "300",
                  trigger: "click"
                },
                model: {
                  value: _vm.locationNumberVisible,
                  callback: function($$v) {
                    _vm.locationNumberVisible = $$v
                  },
                  expression: "locationNumberVisible"
                }
              },
              [
                _c("LocationComponents", {
                  ref: "LocationComponents",
                  on: { select: _vm.locationSelect }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("入库人：" + _vm._s(_vm.form.inWarehouseName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("入库时间：" + _vm._s(_vm.form.inWarehouseTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核人：" + _vm._s(_vm.form.examineName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("审核时间：" + _vm._s(_vm.form.examineTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账人：" + _vm._s(_vm.form.accountName))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("记账时间：" + _vm._s(_vm.form.accountTime))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            key: "remove-button",
                            attrs: {
                              type: "danger",
                              disabled: !(
                                _vm.form.inWarehouseStatus == 1 &&
                                _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 1 || !_vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "invalid-button",
                            attrs: { type: "danger", icon: "el-icon-close" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v("作废")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus === 6
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "order-delete-button",
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v("删除订单")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "storage-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toFormStorage()
                              }
                            }
                          },
                          [_vm._v("暂存")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "submit-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toFormSubmit()
                              }
                            }
                          },
                          [_vm._v("提交")]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "un-submit-button",
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.formUnSubmit()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "check-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.formCheck }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-check" }),
                            _vm._v(" 审核")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.inWarehouseStatus == 3
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "un-check-button",
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnCheck }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销审核")
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "prevent-re-click",
                            rawName: "v-prevent-re-click"
                          }
                        ],
                        key: "print-button",
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("SpecsSearchComponents", {
        ref: "SpecsSearchComponents",
        on: { confirm: _vm.specsAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.specsAddToList }
      }),
      _c("CheckComponents", {
        ref: "CheckComponents",
        on: { confirm: _vm.loadOrder }
      }),
      _c("ImportFromExcelComponent", {
        ref: "ImportFromExcelComponentRef",
        on: { add: _vm.specsAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }