import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getStockMainWarningList } from '@/api/stock.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      dbList: [],
      dbLoading: false,
      form: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '库存预警';
      that.dbList = [];
      that.dbLoading = false;
      that.form = {
        page: 1,
        pageSize: 10,
        total: 0,
        isWarning: 1,
        stockQuantityWarning: 1
      };
      that.loadWarning();
    },
    loadWarning: function loadWarning() {
      var that = this;
      that.dbLoading = true;
      that.dbList = [];
      getStockMainWarningList(that.form).then(function (res) {
        that.dbList = res.list;
        that.form.total = res.total;
      }).finally(function () {
        that.dbLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadWarning();
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    toStock: function toStock(row) {
      var that = this;
      that.$router.push({
        name: 'stock-detail-index',
        params: {
          productName: row.main.productName,
          warehouseId: row.warehouseId
        }
      });
      that.dialogClose();
    },
    validateFormatter: function validateFormatter(time) {
      var date1 = dayjs();

      if (date1.diff(time, 'day') < 1) {
        return time;
      } else {
        return '已过期';
      }
    },
    lowFormatter: function lowFormatter(row) {
      if (row.normalQuantity <= row.specs.lowInventory) {
        return '<span class="el-tag el-tag--small el-tag--danger el-tag--dark">' + row.specs.lowInventory + '</span>';
      } else {
        return row.specs.lowInventory;
      }
    },
    highFormatter: function highFormatter(row) {
      if (row.normalQuantity >= row.specs.highInventory) {
        return '<span class="el-tag el-tag--small el-tag--danger el-tag--dark">' + row.specs.highInventory + '</span>';
      } else {
        return row.specs.highInventory;
      }
    },
    normalQuantityFormatter: function normalQuantityFormatter(row) {
      if (row.normalQuantity <= row.lowInventory) {
        return '<span><i class="fa fa-arrow-down "></i>' + row.normalQuantity + '</span>';
      }

      if (row.normalQuantity >= row.highInventory) {
        return '<span><i class="fa fa-arrow-up "></i>' + row.normalQuantity + '</span>';
      }
    },
    cellClassName: function cellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (column.property === 'normalQuantity') {
        if (row.normalQuantity <= row.lowInventory) {
          return 'text-danger';
        }

        if (row.normalQuantity >= row.highInventory) {
          return 'text-warning';
        }
      }
    }
  }
};