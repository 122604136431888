import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listInvoiceNew } from '@/api/invoice.js';
import { listRepaymentStatus } from '@/api/dict';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      dbList: [],
      dbLoading: false,
      form: {},
      receiveStatusList: listRepaymentStatus()
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '超期未回款预警';
      that.dbList = [];
      that.dbLoading = false;
      that.form = {
        page: 1,
        pageSize: 10,
        total: 0,
        overdueDays: 2
      };
      that.loadData();
    },
    loadData: function loadData() {
      var that = this;
      that.dbLoading = true;
      that.dbList = [];
      listInvoiceNew(that.form).then(function (res) {
        res.list.forEach(function (row) {
          row.taxAmount = row.taxAmount.toFixed(2);
          var findRow = that.receiveStatusList.find(function (item) {
            return item.ID === row.receiveStatus;
          });

          if (findRow) {
            row.receiveStatusName = findRow.name;
          } else {
            row.receiveStatusName = null;
          }

          if (row.overdueDays > 0) {
            row.overdueDaysDesc = '<span class="text-danger">超期' + row.overdueDays + '天</span>';
          } else {
            row.overdueDaysDesc = '正常';
          }

          that.dbList.push(row);
        });
        that.form.total = res.total;
      }).finally(function () {
        that.dbLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadData();
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    toSkip: function toSkip(row) {
      var that = this;
      that.dialogClose();
      that.$router.push({
        name: 'financial-invoice-output',
        params: {
          invoiceNumber: row.invoiceNumber
        }
      });
    }
  }
};