var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1000px",
        "close-on-click-modal": false,
        "append-to-body": true,
        "before-close": _vm.dialogClose,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "specsForm",
          attrs: { inline: true, size: "mini", "label-suffix": ":" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.specsFormSubmit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.specsForm,
            callback: function($$v) {
              _vm.specsForm = $$v
            },
            expression: "specsForm"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品主码" } },
            [
              _c("el-input", {
                staticClass: "bar-input",
                attrs: { placeholder: "产品主码", clearable: "" },
                model: {
                  value: _vm.specsForm.barCode,
                  callback: function($$v) {
                    _vm.$set(_vm.specsForm, "barCode", $$v)
                  },
                  expression: "specsForm.barCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "产品编号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "产品编号", clearable: "" },
                model: {
                  value: _vm.specsForm.productNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.specsForm, "productNumber", $$v)
                  },
                  expression: "specsForm.productNumber"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物资名称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "物资名称", clearable: "" },
                model: {
                  value: _vm.specsForm.productName,
                  callback: function($$v) {
                    _vm.$set(_vm.specsForm, "productName", $$v)
                  },
                  expression: "specsForm.productName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "规格型号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "规格型号", clearable: "" },
                model: {
                  value: _vm.specsForm.specificationModel,
                  callback: function($$v) {
                    _vm.$set(_vm.specsForm, "specificationModel", $$v)
                  },
                  expression: "specsForm.specificationModel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "序列号" } },
            [
              _c("el-input", {
                attrs: { placeholder: "序列号" },
                model: {
                  value: _vm.specsForm.trackingCode,
                  callback: function($$v) {
                    _vm.$set(_vm.specsForm, "trackingCode", $$v)
                  },
                  expression: "specsForm.trackingCode"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "品牌" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择品牌", clearable: "" },
                  model: {
                    value: _vm.specsForm.brandId,
                    callback: function($$v) {
                      _vm.$set(_vm.specsForm, "brandId", $$v)
                    },
                    expression: "specsForm.brandId"
                  }
                },
                _vm._l(_vm.brandList, function(item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.ID }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", "label-width": "0" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.specsFormSubmit }
                },
                [_vm._v("查 询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "detailForm",
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.specsAddToList.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.stockLoading,
                  expression: "stockLoading"
                }
              ],
              ref: "specsListTable",
              attrs: {
                data: _vm.specsList,
                border: "",
                "highlight-current-row": "",
                size: "mini",
                "max-height": "260px",
                "header-cell-style": {
                  background: "#f5f7fa",
                  borderColor: "#ebeef5",
                  color: "#333"
                }
              },
              on: { "selection-change": _vm.selectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "40", fixed: "" }
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  index: function(index) {
                    return _vm.indexMethod(index, _vm.specsForm)
                  },
                  label: "序号",
                  width: "60",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "",
                  label: "产地/品牌",
                  align: "center",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.productPlace) +
                            "/" +
                            _vm._s(scope.row.brandName)
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  property: "barCode",
                  label: "产品主码",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  property: "productNumber",
                  label: "产品编码",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "productName",
                  label: "物资名称",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "specificationModel",
                  label: "规格型号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "unit",
                  label: "单位",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  property: "registrationNumber",
                  label: "注册证号",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  property: "genericName",
                  label: "通用名",
                  align: "center",
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { margin: "10px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                  "page-sizes": [5, 10, 20, 50, 100],
                  align: "center",
                  total: _vm.specsForm.total,
                  "current-page": _vm.specsForm.page,
                  "page-size": _vm.specsForm.pageSize
                },
                on: {
                  "current-change": _vm.specsListPageChange,
                  "size-change": _vm.specsListSizeChange
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center"
              }
            },
            [
              _c("el-button", { on: { click: _vm.dialogClose } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  staticStyle: { "margin-right": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.specsAddToList }
                },
                [_vm._v("添加")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.barCodeDialogTitle,
            visible: _vm.barCodeDialogVisible,
            width: "300px",
            "close-on-click-modal": false,
            "append-to-body": true,
            "before-close": _vm.barCodeDialogClose,
            "modal-append-to-body": false,
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.barCodeDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "specsForm",
              attrs: { "label-width": "70px", size: "small" },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              },
              model: {
                value: _vm.specsForm,
                callback: function($$v) {
                  _vm.specsForm = $$v
                },
                expression: "specsForm"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "0" } },
                [
                  _c("el-input", {
                    ref: "barCodeInput",
                    staticClass: "bar-input",
                    attrs: { placeholder: _vm.barInputPlaceholder },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.barCodeSearch.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.specsForm.barCode,
                      callback: function($$v) {
                        _vm.$set(_vm.specsForm, "barCode", $$v)
                      },
                      expression: "specsForm.barCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }