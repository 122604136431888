var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "1200px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center"
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.orderType,
                    callback: function($$v) {
                      _vm.orderType = $$v
                    },
                    expression: "orderType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "随货同行单" } }, [
                    _vm._v("随货同行单")
                  ]),
                  _c("el-radio", { attrs: { label: "出库单" } }, [
                    _vm._v("出库单")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-popover",
                {
                  ref: "optionsPopover",
                  attrs: { placement: "bottom", trigger: "click" }
                },
                [
                  _c("TableHeadComponent", {
                    ref: "TableHeadComponentRef",
                    attrs: {
                      "cache-name": _vm.cacheName,
                      "remote-columns-list": _vm.remoteColumnsList
                    },
                    on: { "save-table-set": _vm.saveTableSet }
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        slot: "reference",
                        type: "primary",
                        size: "mini"
                      },
                      slot: "reference"
                    },
                    [_vm._v("字段自定义")]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.printClick }
                },
                [_vm._v("点击打印")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.exportExcel }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            "element-loading-text": "拼命加载中...",
            "element-loading-spinner": "el-icon-loading"
          }
        },
        [
          _vm.loading === false
            ? _c(
                "div",
                {
                  ref: "printContainer" + _vm.$route.name,
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "printContainer" + _vm.$route.name,
                    loading: _vm.loading
                  }
                },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("div", { staticClass: "title-content" }, [
                      _c("div", [_vm._v(_vm._s(_vm.corporateName))]),
                      _c("div", [_vm._v(_vm._s(_vm.orderType))])
                    ]),
                    _c("div", { staticClass: "image-content" }, [
                      _c("img", { attrs: { id: "barcode" } })
                    ])
                  ]),
                  _c("div", { staticClass: "table-header" }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("购货公司： "),
                      _vm.orderInfo.issueType === 2 ||
                      _vm.orderInfo.issueType === 3 ||
                      _vm.orderInfo.issueType === 5
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.orderInfo.distributorName))
                          ])
                        : _vm._e(),
                      _vm.orderInfo.issueType === 1 ||
                      _vm.orderInfo.issueType === 4
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.orderInfo.hospitalName))
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("供货公司：" + _vm._s(_vm.corporateName))
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("收货地址： "),
                      _c("span", [_vm._v(_vm._s(_vm.orderInfo.addressManu))])
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("发货地址：" + _vm._s(_vm.corporateAddress))
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "收货人：" + _vm._s(_vm.orderInfo.contactPersonManu)
                      )
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v(
                        "出库人员：" +
                          _vm._s(_vm.orderInfo.nameWarehousePersonnel)
                      )
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("联系方式：" + _vm._s(_vm.orderInfo.mobileManu))
                    ]),
                    _c("div", { staticClass: "item" }, [
                      _vm._v("出库时间：" + _vm._s(_vm.orderInfo.deliveryTime))
                    ]),
                    _c("div", { staticClass: "item-alone" }, [
                      _vm._v(
                        " 备注信息：" + _vm._s(_vm.orderInfo.remarks) + " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "detail-table" }, [
                    _c("table", [
                      _c("thead", [
                        _c(
                          "tr",
                          [
                            _vm._l(_vm.remoteColumnsList, function(
                              item,
                              index
                            ) {
                              return [
                                item.checked === true
                                  ? _c("th", { key: "field-" + index }, [
                                      _vm._v(_vm._s(item.label))
                                    ])
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _c(
                        "tbody",
                        [
                          _vm._l(_vm.detailList, function(item, index) {
                            return _c(
                              "tr",
                              { key: "data-col-" + index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          { key: "data-item-" + index2 },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.prop],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          _vm._l(_vm.getSummary(), function(item, index) {
                            return _c(
                              "tr",
                              { key: index },
                              [
                                _vm._l(_vm.remoteColumnsList, function(
                                  item2,
                                  index2
                                ) {
                                  return [
                                    item2.checked === true
                                      ? _c(
                                          "td",
                                          {
                                            key: "summary-" + index2,
                                            staticClass: "summary"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.dataFormatter(
                                                  item[item2.prop],
                                                  item2.type
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "table-footer" }, [
                      _c("div", { staticClass: "item" }, [
                        _vm._v("出库人员：__________________")
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("复核人员：__________________")
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("出库印章：")
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("收货人员：__________________")
                      ]),
                      _c("div", { staticClass: "item" }, [
                        _vm._v("收货日期：__________________")
                      ])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }