var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("直销出库")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    type: "primary",
                    size: "mini"
                  },
                  on: { click: _vm.orderAdd }
                },
                [_vm._v("新增出库单")]
              ),
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          filterable: ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseId", $$v)
                          },
                          expression: "searchForm.warehouseId"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "制单日期" },
                        model: {
                          value: _vm.searchForm.timeType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "timeType", $$v)
                          },
                          expression: "searchForm.timeType"
                        }
                      },
                      _vm._l(_vm.timeTypeList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        "unlink-panels": true,
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd"
                      },
                      on: { change: _vm.dateRangeChange },
                      model: {
                        value: _vm.searchForm.dateRange,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "dateRange", $$v)
                        },
                        expression: "searchForm.dateRange"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "制单状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchForm.searchStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "searchStatus", $$v)
                          },
                          expression: "searchForm.searchStatus"
                        }
                      },
                      _vm._l(_vm.statusList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "归属公司" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.subsidiaryId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "subsidiaryId", $$v)
                              },
                              expression: "searchForm.subsidiaryId"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item) {
                            return _c("el-option", {
                              key: "subsidiaryId" + item.ID,
                              attrs: { label: item.companyName, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "出诊类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.searchForm.emergencyNot,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "emergencyNot", $$v)
                              },
                              expression: "searchForm.emergencyNot"
                            }
                          },
                          _vm._l(_vm.emergencyNotList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "出库单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入出库单号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.deliveryOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "deliveryOrderNo", $$v)
                            },
                            expression: "searchForm.deliveryOrderNo"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "医院" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择医院",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.hospitalId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "hospitalId", $$v)
                              },
                              expression: "searchForm.hospitalId"
                            }
                          },
                          _vm._l(_vm.hospitalList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "品牌" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择品牌",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.brandId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "brandId", $$v)
                              },
                              expression: "searchForm.brandId"
                            }
                          },
                          _vm._l(_vm.brandList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品批号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品批号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.batchNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "batchNumber", $$v)
                            },
                            expression: "searchForm.batchNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品编码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productNumber", $$v)
                            },
                            expression: "searchForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品规格" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品规格",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.specificationModel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "specificationModel",
                                $$v
                              )
                            },
                            expression: "searchForm.specificationModel"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "序列号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入序列号", clearable: "" },
                          model: {
                            value: _vm.searchForm.trackingCode,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "trackingCode", $$v)
                            },
                            expression: "searchForm.trackingCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品名称",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productName", $$v)
                            },
                            expression: "searchForm.productName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "供应商" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择供应商",
                              clearable: "",
                              filterable: ""
                            },
                            model: {
                              value: _vm.searchForm.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                              },
                              expression: "searchForm.manufacturerId"
                            }
                          },
                          _vm._l(_vm.supplierList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: {
                                label: item.name,
                                value: item.ID,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入备注", clearable: "" },
                          model: {
                            value: _vm.searchForm.remark,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "remark", $$v)
                            },
                            expression: "searchForm.remark"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品主码" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品主码",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.barCode,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "barCode", $$v)
                            },
                            expression: "searchForm.barCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList,
            slotIndex: true
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "deliveryOrderNo",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    {
                      ref: "barCodePopover" + row.ID,
                      attrs: { placement: "right", trigger: "click" }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { id: "barCodeImage" + row.ID } })
                      ]),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            slot: "reference",
                            underline: false,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.makeBarCode(
                                row.ID,
                                row.deliveryOrderNo
                              )
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(row.deliveryOrderNo))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "index",
              fn: function(ref) {
                var row = ref.row
                var index = ref.index
                return [
                  row.quantityUsedSum === 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            height: "32px",
                            overflow: "hidden",
                            "margin-top": "-7px"
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                (_vm.searchForm.page - 1) *
                                  _vm.searchForm.pageSize +
                                  index +
                                  1
                              )
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "margin-top": "-5px",
                                background: "#409EFF",
                                color: "#fff",
                                "line-height": "16px",
                                margin: "-5px auto 0 auto",
                                width: "45px"
                              }
                            },
                            [_vm._v("无消耗")]
                          )
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.searchForm.page - 1) *
                              _vm.searchForm.pageSize +
                              index +
                              1
                          )
                        )
                      ])
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function($event) {
                              return _vm.orderEdit(row)
                            }
                          }
                        },
                        [_vm._v("订单详情")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize,
                layout: "total, sizes, prev, pager, next, jumper"
              },
              on: {
                "size-change": _vm.orderSizeChange,
                "current-change": _vm.orderPageChange
              }
            })
          ],
          1
        ),
        _c("el-divider", { attrs: { "content-position": "left" } }, [
          _vm._v("耗材明细（出库总数量："),
          _c("span", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.sumDeliveryQuantity))
          ]),
          _vm._v("）")
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.detailList,
              size: "small",
              "highlight-current-row": "",
              border: "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-class-name": _vm.rowClassName
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                },
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "brandName",
                label: "品牌",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.productName",
                label: "物资名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.row.consumTypeId
                              ? scope.row.name
                              : scope.row.main.productName
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.genericName",
                label: "通用名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockMain.manufacturerName",
                label: "供应商",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.specificationModel",
                width: "100",
                label: "规格型号",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.medCode",
                width: "100",
                label: "医保编码",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockMain.batch_number",
                label: "生产批号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockMain.trackingCode",
                label: "序列号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "deliveryQuantity",
                width: "100",
                label: "出库数量",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "quantityUsed",
                width: "100",
                label: "使用数量",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.registrationNumber",
                width: "150",
                label: "注册证号",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockMain.normalQuantity",
                width: "150",
                label: "在库数量",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockMain.manufacturer_date",
                width: "150",
                label: "生产日期",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "stockMain.validity_date",
                width: "150",
                label: "有效期",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.brandName",
                width: "150",
                label: "品牌",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.unit",
                width: "150",
                label: "单位",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "",
                width: "150",
                label: "包装规格",
                align: "center",
                "show-overflow-tooltip": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.specs.packNum) +
                          _vm._s(scope.row.specs.unit) +
                          "/" +
                          _vm._s(scope.row.specs.packUnit) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.manufacturer",
                width: "150",
                label: "生产厂家",
                align: "center",
                "show-overflow-tooltip": ""
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailForm.total,
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize,
                "page-sizes": [5, 10, 20, 50, 100]
              },
              on: {
                "current-change": _vm.detailPageChange,
                "size-change": _vm.detailSizeChange
              }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }