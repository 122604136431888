var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("订货管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售订货单")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  _vm._s(_vm.form.id ? "销售订货单详情" : "新建销售订货单")
                )
              ])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 销售单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              size: "mini",
              "label-width": "120px",
              "show-message": false,
              disabled: _vm.form.state > 1,
              loading: _vm.formLoading
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "distributorId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              disabled: _vm.detailList.length > 0
                            },
                            on: { change: _vm.distributorChange },
                            model: {
                              value: _vm.form.distributorId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "distributorId", $$v)
                              },
                              expression: "form.distributorId"
                            }
                          },
                          _vm._l(_vm.retailerList, function(item, index) {
                            return _c("el-option", {
                              key: "distributorId" + index,
                              attrs: {
                                value: item.ID,
                                label: item.name,
                                disabled: !item.show_status
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售员", prop: "sellUid" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "buyerUid",
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.form.sellUid,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sellUid", $$v)
                              },
                              expression: "form.sellUid"
                            }
                          },
                          _vm._l(_vm.userList, function(item) {
                            return _c("el-option", {
                              key: "sellUid-" + item.uid,
                              attrs: { label: item.name, value: item.uid }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售日期", prop: "sellDate" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.form.sellDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sellDate", $$v)
                            },
                            expression: "form.sellDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "发票" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "发票", disabled: true },
                            model: {
                              value: _vm.invoiceNumberList,
                              callback: function($$v) {
                                _vm.invoiceNumberList = $$v
                              },
                              expression: "invoiceNumberList"
                            }
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                width: "150px",
                                "text-align": "center",
                                "border-left": "1px solid #ebeef5"
                              }
                            },
                            [
                              _c(
                                "el-link",
                                {
                                  staticStyle: { margin: "0 10px" },
                                  attrs: { type: "primary", underline: false },
                                  on: { click: _vm.invoiceAdd }
                                },
                                [_vm._v("添加发票")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货地址", prop: "receiveAddress" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "收货地址",
                              maxlength: "500",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.receiveAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receiveAddress", $$v)
                              },
                              expression: "form.receiveAddress"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货人", prop: "receiveName" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "收货人", maxlength: "50" },
                            model: {
                              value: _vm.form.receiveName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "receiveName", $$v)
                              },
                              expression: "form.receiveName"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式", prop: "contactInfo" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "联系方式",
                              maxlength: "200"
                            },
                            model: {
                              value: _vm.form.contactInfo,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "contactInfo", $$v)
                              },
                              expression: "form.contactInfo"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 16, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: { placeholder: "备注", maxlength: "200" },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit el-input__icon",
                              attrs: { slot: "suffix" },
                              slot: "suffix"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.state !== 1
                    },
                    on: { click: _vm.selectStock }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.state !== 1
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", { attrs: { "aria-hidden": "true" } }),
                    _vm._v(" 扫码录入 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: _vm.form.state !== 1
                    },
                    on: { click: _vm.importExcel }
                  },
                  [
                    _c("i", { attrs: { "aria-hidden": "true" } }),
                    _vm._v(" EXCEL导入 ")
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v("销售总数："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  )
                ]),
                _c("div", { staticStyle: { "margin-left": "30px" } }, [
                  _vm._v("总金额："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalAmount()))]
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("YTable", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailLoading,
                  expression: "detailLoading"
                }
              ],
              ref: "dragTableRef",
              attrs: {
                hasIndex: true,
                hasPagination: true,
                pagination: _vm.detailListForm,
                "route-name": _vm.$route.name,
                columns: _vm.columns,
                rowClassName: _vm.rowClassName,
                data: _vm.detailListForm.data.slice(
                  (_vm.detailListForm.page - 1) * _vm.detailListForm.pageSize,
                  _vm.detailListForm.page * _vm.detailListForm.pageSize
                )
              },
              on: {
                "row-click": _vm.detailRowClick,
                pageChange: _vm.detailListFormPageChange,
                sizeChange: _vm.detailListFormSizeChange
              },
              scopedSlots: _vm._u([
                {
                  key: "sellQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.state === 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "sellQuantity" + index,
                              attrs: {
                                prop: "data." + index + ".sellQuantity",
                                rules: _vm.detailListFormRules.sellQuantity
                              }
                            },
                            [
                              _c("el-input-number", {
                                ref: "sellQuantity-" + index,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "销售数量",
                                  min: 0,
                                  precision: 0,
                                  controls: false
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "complimentaryQuantity-" + index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.sellQuantity,
                                  callback: function($$v) {
                                    _vm.$set(row, "sellQuantity", _vm._n($$v))
                                  },
                                  expression: "row.sellQuantity"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [_vm._v(_vm._s(row.sellQuantity))])
                    ]
                  }
                },
                {
                  key: "complimentaryQuantity",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.state === 1
                        ? _c(
                            "el-form-item",
                            {
                              key: "complimentaryQuantity" + index,
                              attrs: {
                                prop:
                                  "data." + index + ".complimentaryQuantity",
                                rules:
                                  _vm.detailListFormRules.complimentaryQuantity
                              }
                            },
                            [
                              _c("el-input-number", {
                                ref: "complimentaryQuantity-" + index,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "赠送数量",
                                  controls: false,
                                  precision: 0
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return function(event) {
                                      return _vm.nextFocus(
                                        "sellUnitPrice-" + index,
                                        event
                                      )
                                    }.apply(null, arguments)
                                  }
                                },
                                model: {
                                  value: row.complimentaryQuantity,
                                  callback: function($$v) {
                                    _vm.$set(
                                      row,
                                      "complimentaryQuantity",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "row.complimentaryQuantity"
                                }
                              })
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(_vm._s(row.complimentaryQuantity))
                          ])
                    ]
                  }
                },
                {
                  key: "sellUnitPrice",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.state === 1
                        ? _c("el-input-number", {
                            ref: "sellUnitPrice-" + index,
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              min: 0,
                              precision: 2,
                              controls: false
                            },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return function(event) {
                                  return _vm.nextFocus(
                                    "remarks-" + index,
                                    event
                                  )
                                }.apply(null, arguments)
                              }
                            },
                            model: {
                              value: row.sellUnitPrice,
                              callback: function($$v) {
                                _vm.$set(row, "sellUnitPrice", $$v)
                              },
                              expression: "row.sellUnitPrice"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.sellUnitPrice))])
                    ]
                  }
                },
                {
                  key: "remarks",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    return [
                      _vm.form.state === 1
                        ? _c("el-input", {
                            ref: "remarks-" + index,
                            attrs: { size: "mini" },
                            on: {
                              change: function($event) {
                                return _vm.detailSaveOne(row)
                              }
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return function(event) {
                                  return _vm.nextFocus(
                                    "sellQuantity-" + (index + 1),
                                    event
                                  )
                                }.apply(null, arguments)
                              }
                            },
                            model: {
                              value: row.remarks,
                              callback: function($$v) {
                                _vm.$set(row, "remarks", $$v)
                              },
                              expression: "row.remarks"
                            }
                          })
                        : _c("span", [_vm._v(_vm._s(row.remarks))])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.state == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              disabled: !(
                                _vm.form.state == 1 && _vm.detailRowIndex >= 0
                              )
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行")]
                        )
                      : _vm._e(),
                    _vm.form.state == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formStorage)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-save" }),
                            _vm._v(" 暂存")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.state == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.formSubmit)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-send" }),
                            _vm._v(" 提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.state == 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger" },
                            on: { click: _vm.formUnSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.state === 1 && _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderInvalid }
                          },
                          [_vm._v(" 作废")]
                        )
                      : _vm._e(),
                    (_vm.form.state === 1 || _vm.form.state === 5) &&
                    _vm.form.id
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: { click: _vm.orderDelete }
                          },
                          [_vm._v(" 删除订单")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { add: _vm.stockAddToList, update: _vm.stockUpdate }
      }),
      _c("InvoiceAddComponents", {
        ref: "InvoiceAddComponents",
        on: { confirm: _vm.invoiceAddConfirm }
      }),
      _c("ImportExcelComponents", {
        ref: "ImportExcelComponents",
        on: { confirm: _vm.stockAddToList }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }