var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("客户信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("生产商管理")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-upload2",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.importFromExcel()
                    }
                  }
                },
                [_vm._v("批量导入")]
              ),
              _vm.isPromoter
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary"
                      },
                      on: { click: _vm.itemAdd }
                    },
                    [_vm._v("新增生产商")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.pageForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchManufacturer()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "生产商名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入生产商名称", clearable: "" },
                      model: {
                        value: _vm.pageForm.name,
                        callback: function($$v) {
                          _vm.$set(_vm.pageForm, "name", $$v)
                        },
                        expression: "pageForm.name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "生产商简称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入生产商简称", clearable: "" },
                      model: {
                        value: _vm.pageForm.short_name,
                        callback: function($$v) {
                          _vm.$set(_vm.pageForm, "short_name", $$v)
                        },
                        expression: "pageForm.short_name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.searchManufacturer()
                          }
                        }
                      },
                      [_vm._v("查询")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading"
              }
            ],
            attrs: {
              data: _vm.tableData,
              border: "",
              stripeDELETE: "",
              "highlight-current-row": "",
              size: "mini",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              }
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.pageForm)
                },
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "name", label: "生产商名称", align: "center" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "short_name",
                label: "生产商简称",
                width: "300",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "show_status",
                label: "状态",
                width: "100",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: {
                          disabled: !scope.row.can_edit,
                          "active-color": "#409EFF",
                          "inactive-color": "#ddd",
                          "active-value": true,
                          "inactive-value": false
                        },
                        on: {
                          change: function($event) {
                            return _vm.statusChange(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.show_status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "show_status", $$v)
                          },
                          expression: "scope.row.show_status"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { width: "100", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.statusFormatter(scope.row))
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "250", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "control-column" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.itemView(scope.row)
                                }
                              }
                            },
                            [_vm._v("查看")]
                          ),
                          _vm.isPromoter
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    disabled: !scope.row.can_edit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.itemEdit(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("编辑")]
                              )
                            : _vm._e(),
                          _vm.isPromoter
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "danger",
                                    disabled: !scope.row.can_edit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.itemDelete(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next, total",
              align: "center",
              total: _vm.pageForm.total,
              "current-page": _vm.pageForm.page,
              "page-size": _vm.pageForm.pageSize
            },
            on: { "current-change": _vm.pageChange }
          })
        ],
        1
      ),
      _c("ManufacturerEditorComponent", {
        ref: "ManufacturerEditorComponent",
        on: { reload: _vm.init }
      }),
      _c("ImportComponent", {
        ref: "ImportComponentRef",
        on: {
          reload: function($event) {
            return _vm.loadData()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }