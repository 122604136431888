var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("统计报表")]),
              _c("el-breadcrumb-item", [_vm._v("出入库报表")]),
              _c("el-breadcrumb-item", [_vm._v("入库成本报表")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库选择" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          multiple: "",
                          "collapse-tags": ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseIds,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseIds", $$v)
                          },
                          expression: "searchForm.warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "入库类型" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.inWarehouseType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "inWarehouseType", $$v)
                          },
                          expression: "searchForm.inWarehouseType"
                        }
                      },
                      _vm._l(_vm.inWarehouseTypeList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "开始日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.timeStart,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "timeStart", $$v)
                            },
                            expression: "searchForm.timeStart"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "结束日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd"
                          },
                          model: {
                            value: _vm.searchForm.timeEnd,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "timeEnd", $$v)
                            },
                            expression: "searchForm.timeEnd"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "供应商" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.manufacturerId,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                              },
                              expression: "searchForm.manufacturerId"
                            }
                          },
                          _vm._l(_vm.supplierList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品线" } },
                      [
                        _c("el-cascader", {
                          attrs: {
                            options: _vm.productLineList,
                            props: _vm.productLineProps,
                            "show-all-levels": true,
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productLineCateId,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productLineCateId", $$v)
                            },
                            expression: "searchForm.productLineCateId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "物资名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入物资名称",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productName", $$v)
                            },
                            expression: "searchForm.productName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品编号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.productNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "productNumber", $$v)
                            },
                            expression: "searchForm.productNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "订单状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.searchForm.inWarehouseStatus,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "inWarehouseStatus",
                                  $$v
                                )
                              },
                              expression: "searchForm.inWarehouseStatus"
                            }
                          },
                          _vm._l(_vm.inWarehouseStatusList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "汇总方式" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.reportTypeChange },
                        model: {
                          value: _vm.searchForm.reportType,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "reportType", $$v)
                          },
                          expression: "searchForm.reportType"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("明细")
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("汇总")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          key: "dragTable" + _vm.timestamp,
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name":
              _vm.$route.name +
              (_vm.searchForm.reportType === 0 ? "detail" : "gather"),
            columns:
              _vm.searchForm.reportType === 0
                ? _vm.tableHeadDetail
                : _vm.tableHeadGather,
            data: _vm.tableData
          }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "size-change": _vm.pageSizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }