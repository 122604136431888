var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售审核")]),
              _c("el-breadcrumb-item", [_vm._v("销售核销")]),
              _c("el-breadcrumb-item", [_vm._v("销售核销详情")])
            ],
            1
          ),
          _vm.orderInfo
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 销售单号：" + _vm._s(_vm.orderInfo.salesOrderNo) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _vm.orderInfo
          ? _c(
              "el-form",
              {
                ref: "form",
                staticClass: "form-table",
                attrs: {
                  model: _vm.orderInfo,
                  size: "mini",
                  "label-width": "85px",
                  "show-message": false,
                  "label-suffix": ":",
                  loading: _vm.formLoading
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-wrap": "wrap" },
                    attrs: { type: "flex" }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售单号" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.salesOrderNo))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "客户名称" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.customerName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售人" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.nameSalesperson))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售日期" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.returnDate))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售类型" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.salesTypeName))
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "销售金额" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.totalSalesAmount.toFixed(2))
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "开票情况" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                margin: "0 10px 0 15px",
                                display: "flex"
                              }
                            },
                            [
                              _c("div", { staticStyle: { flex: "1" } }, [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.orderInfo.invoiceStatusName
                                    )
                                  }
                                })
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "border-left": "1px solid #ebeef5",
                                    width: "80px",
                                    "text-align": "center"
                                  }
                                },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        type: "primary"
                                      },
                                      on: { click: _vm.showInvoice }
                                    },
                                    [_vm._v("发票详情")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "回款情况" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.orderInfo.receiveStatusName
                                )
                              }
                            })
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 8, xl: 8 } },
                      [
                        _c("el-form-item", { attrs: { label: "退货金额" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(
                              _vm._s(_vm.orderInfo.returnSalesAmount.toFixed(2))
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { sm: 12, lg: 24, xl: 24 } },
                      [
                        _c("el-form-item", { attrs: { label: "备注" } }, [
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.orderInfo.remarks))
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "1" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("1")
                      }
                    }
                  },
                  [_vm._v("销售单详情")]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "2" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("2")
                      }
                    }
                  },
                  [_vm._v("退货详情")]
                )
              ]
            ),
            _c("div", [
              _vm.tabActiveId === "1"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "font-size": "14px",
                        "padding-right": "20px",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c("span", { staticStyle: { color: "#f00" } }, [
                        _vm._v("销售数量：" + _vm._s(_vm.totalQuantity))
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-left": "20px" }
                        },
                        [
                          _vm._v(
                            "销售金额：" + _vm._s(_vm.totalAmount.toFixed(2))
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm.tabActiveId === "2"
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "font-size": "14px",
                        "padding-right": "20px",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c("span", { staticStyle: { color: "#f00" } }, [
                        _vm._v("退货数量：" + _vm._s(_vm.totalQuantity))
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#f00", "margin-left": "20px" }
                        },
                        [
                          _vm._v(
                            "退货金额：" + _vm._s(_vm.totalAmount.toFixed(2))
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabActiveId === "1",
              expression: "tabActiveId==='1'"
            }
          ],
          ref: "detailDataTableRef",
          attrs: {
            hasPagination: true,
            hasIndex: true,
            hasDrag: false,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name + "-detail-table",
            columns: _vm.detailColumns,
            data: _vm.detailList
          },
          on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange }
        }),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading"
            },
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabActiveId === "2",
              expression: "tabActiveId==='2'"
            }
          ],
          ref: "returnDataTableRef",
          attrs: {
            hasPagination: true,
            hasIndex: true,
            hasDrag: false,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name + "-return-table",
            columns: _vm.returnColumns,
            data: _vm.detailList
          },
          on: { pageChange: _vm.pageChange, sizeChange: _vm.sizeChange }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.orderInfo
            ? _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.formLoading,
                      expression: "formLoading"
                    }
                  ],
                  staticStyle: { margin: "0px" },
                  attrs: { size: "small" },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { margin: "0" } },
                    [
                      _vm.orderInfo.verStatus === 2
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "prevent-re-click",
                                  rawName: "v-prevent-re-click"
                                }
                              ],
                              attrs: { type: "primary" },
                              on: { click: _vm.formCheck }
                            },
                            [_vm._v("核销")]
                          )
                        : _vm._e(),
                      _vm.orderInfo.verStatus === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.formCheckForce }
                            },
                            [_vm._v("强制核销")]
                          )
                        : _vm._e(),
                      _vm.orderInfo.verificationStatus === 2
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.unCheck }
                            },
                            [_vm._v("撤回核销")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toPrint()
                            }
                          }
                        },
                        [_vm._v("打印")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("InvoiceListComponent", { ref: "InvoiceListComponentRef" }),
      _c("PrintComponent", { ref: "PrintComponentRef" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }