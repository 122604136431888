var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售审核")]),
              _c("el-breadcrumb-item", [_vm._v("销售单审核")]),
              _c("el-breadcrumb-item", [_vm._v("销售订货单详情")])
            ],
            1
          ),
          _vm.orderInfo.orderNumber
            ? _c(
                "div",
                { staticStyle: { "font-size": "14px", color: "#606266" } },
                [
                  _vm._v(
                    " 销售订货单号：" + _vm._s(_vm.orderInfo.orderNumber) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form-table",
            attrs: {
              model: _vm.orderInfo,
              size: "mini",
              "label-width": "100px",
              "show-message": false,
              disabled: _vm.orderInfo.intelligent === 1,
              loading: _vm.formLoading,
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-row",
              { staticStyle: { "flex-wrap": "wrap" }, attrs: { type: "flex" } },
              [
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "distributorId" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.distributorName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售员", prop: "sellUid" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.sellName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售日期", prop: "sellDate" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.sellDate))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "发票" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.invoiceList))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c("el-form-item", { attrs: { label: "收货地址" } }, [
                      _c("span", { staticClass: "item-text" }, [
                        _vm._v(_vm._s(_vm.orderInfo.receiveAddress))
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "收货人", prop: "receiveName" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.receiveName))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式", prop: "contactInfo" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.contactInfo))
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { sm: 12, lg: 8, xl: 6 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remarks" } },
                      [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(_vm._s(_vm.orderInfo.remarks))
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0"
            }
          },
          [
            _c("div"),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center",
                  "justify-content": "flex-end",
                  "font-size": "14px",
                  color: "#606266"
                }
              },
              [
                _c("div", [
                  _vm._v("销售总数："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalQuantity()))]
                  )
                ]),
                _c("div", { staticStyle: { "margin-left": "30px" } }, [
                  _vm._v("总金额："),
                  _c(
                    "span",
                    { staticStyle: { color: "#f00", "font-weight": "bold" } },
                    [_vm._v(_vm._s(_vm.totalAmount()))]
                  )
                ])
              ]
            )
          ]
        ),
        _c(
          "el-form",
          {
            ref: "detailListForm",
            attrs: {
              model: _vm.detailListForm,
              rules: _vm.detailListFormRules,
              size: "small",
              disabled: _vm.form.intelligent === 1,
              "show-message": false
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                ref: "detailListTable",
                staticStyle: { width: "100%" },
                attrs: {
                  id: "detailListTable",
                  data: _vm.detailListForm.data,
                  border: "",
                  size: "mini",
                  "highlight-current-row": "",
                  "header-cell-style": {
                    background: "#f5f7fa",
                    borderColor: "#ebeef5",
                    color: "#333"
                  },
                  "row-key": _vm.detailRowKey,
                  "row-class-name": _vm.rowClassName,
                  "max-height": "500"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "60",
                    align: "center",
                    index: function(index) {
                      return _vm.indexMethod(index, _vm.detailListForm)
                    }
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productNumber",
                    label: "产品编号",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "产品名称",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "specificationModel",
                    label: "规格型号",
                    width: "100",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "registrationNumber",
                    label: "注册证号",
                    width: "150",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "brandName",
                    width: "100",
                    label: "品牌",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "unit",
                    width: "60",
                    label: "单位",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sellQuantity",
                    label: "销售数量",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sellUnitPrice",
                    label: "销售单价",
                    width: "160",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sellAmount",
                    label: "销售金额",
                    width: "100",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "remarks",
                    label: "备注",
                    width: "200",
                    align: "center",
                    "show-overflow-tooltip": ""
                  }
                }),
                _c("el-table-column", {
                  attrs: { label: "更多", width: "60", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "right",
                                width: "600",
                                trigger: "click"
                              }
                            },
                            [
                              _c("DetailExtendComponents", {
                                key: $index,
                                ref: "DetailExtendComponents",
                                attrs: { row: row }
                              }),
                              _c("el-link", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  padding: "5px"
                                },
                                attrs: {
                                  slot: "reference",
                                  icon: "el-icon-arrow-down",
                                  underline: false
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showSpecs(row.specsId)
                                  }
                                },
                                slot: "reference"
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { margin: "10px 0" } },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    layout: "total, prev, pager, next, jumper",
                    align: "center",
                    "hide-on-single-page": true,
                    total: _vm.detailListForm.total,
                    "current-page": _vm.detailListForm.page,
                    "page-size": _vm.detailListForm.pageSize
                  },
                  on: { "current-change": _vm.detailListFormPageChange }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.state == 7 && _vm.form.intelligent === 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            attrs: { type: "primary" },
                            on: { click: _vm.checkOrder }
                          },
                          [_vm._v(" 审核")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", disabled: !_vm.form.id },
                        on: { click: _vm.orderPrint }
                      },
                      [_vm._v("打印")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("PrintComponents", { ref: "PrintComponents" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }