//
//
//
//
//
//
//
//
//
//
//
//
//
import { createInvoice, updateInvoice, deleteInvoice, listInvoice } from '@/api/purchase.js';
import util from '@/libs/util.js';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '产品详情',
      dialogVisible: false,
      loading: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(main, specs) {
      var that = this;
      that.dialogVisible = true;
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    }
  }
};