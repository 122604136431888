import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listOutWarehouseOrder } from '@/api/stock.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      dbList: [],
      dbLoading: false,
      form: {},
      issueTypeList: [{
        ID: '',
        name: '全部'
      }, {
        ID: 1,
        name: '直销出库'
      }, {
        ID: 2,
        name: '临调出库'
      }, {
        ID: 3,
        name: '销售出库'
      }, {
        ID: 4,
        name: '有台出库'
      }],
      emergencyNotList: [{
        ID: 0,
        name: '全部',
        type: ''
      }, {
        ID: 1,
        name: '急诊',
        type: 'danger'
      }, {
        ID: 2,
        name: '普通',
        type: ''
      }],
      statusList: [{
        ID: '',
        name: '全部',
        type: ''
      }, {
        ID: 1,
        name: '暂存',
        type: 'info'
      }, {
        ID: 2,
        name: '已提交',
        type: 'primary'
      }, {
        ID: 3,
        name: '已复核',
        type: 'primary'
      }, {
        ID: 4,
        name: '发货出库',
        type: 'warning'
      }, {
        ID: 5,
        name: '已作废',
        type: 'danger'
      }, {
        ID: 6,
        name: '已回库',
        type: 'success'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '未回库预警';
      that.dbList = [];
      that.dbLoading = false;
      that.form = {
        page: 1,
        pageSize: 10,
        total: 0,
        isNotReturned: 1
      };
      that.loadOutWarehouseOrder();
    },
    loadOutWarehouseOrder: function loadOutWarehouseOrder() {
      var that = this;
      that.dbLoading = true;
      that.dbList = [];
      listOutWarehouseOrder(that.form).then(function (res) {
        that.dbList = res.list;
        that.form.total = res.total;
      }).finally(function () {
        that.dbLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadOutWarehouseOrder();
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    toView: function toView(row) {
      var that = this;

      if (row.issueType === 1) {
        that.$router.push({
          path: '/stock/outwarehouse/directsales/index/detail/' + row.ID + '/edit'
        });
      } else if (row.issueType === 2) {
        that.$router.push({
          path: '/stock/outwarehouse/consignment/index/detail/' + row.ID + '/edit'
        });
      } else if (row.issueType === 3) {
        that.$router.push({
          path: '/stock/outwarehouse/sales/index/detail/' + row.ID + '/edit'
        });
      } else if (row.issueType === 4) {
        that.$router.push({
          path: '/stock/outwarehouse/youtaiout/index/detail/' + row.ID + '/edit'
        });
      }

      that.dialogClose();
    },
    validateFormatter: function validateFormatter(time) {
      var date1 = dayjs();

      if (date1.diff(time, 'day') < 1) {
        return time;
      } else {
        return '已过期';
      }
    },
    cellClassName: function cellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (column.property === 'info') {
        var date1 = dayjs();

        if (date1.diff(row.license_end_time, 'day') < 1) {
          return 'text-warning';
        } else {
          return 'text-danger';
        }
      }
    },
    issueTypeFormatter: function issueTypeFormatter(issueType) {
      var that = this;
      var find = that.issueTypeList.filter(function (item) {
        return item.ID === issueType;
      });

      if (find.length > 0) {
        return find[0].name;
      }
    },
    emergencyNotFormatter: function emergencyNotFormatter(status) {
      var that = this;
      var find = that.emergencyNotList.filter(function (item) {
        return item.ID === status;
      });

      if (find.length > 0) {
        return '<span class="el-tag el-tag--small el-tag--light el-tag--' + find[0].type + '">' + find[0].name + '</span>';
      } else {
        return '未知';
      }
    },
    statusFormatter: function statusFormatter(status) {
      var that = this;
      var find = that.statusList.filter(function (item) {
        return item.ID === status;
      });

      if (find.length > 0) {
        return '<span class="el-tag el-tag--small el-tag--light el-tag--' + find[0].type + '">' + find[0].name + '</span>';
      }
    },
    orderTypeFormatter: function orderTypeFormatter(row, column) {
      if (row.orderType === 1) {
        return '标配';
      } else if (row.orderType === 2) {
        return '自备工具';
      } else if (row.orderType === 3) {
        return '自备耗材';
      }
    },
    timeFormatter: function timeFormatter(time) {
      return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '';
    }
  }
};