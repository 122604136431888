var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "900px",
        "before-close": _vm.dialogClose,
        "close-on-click-modal": false,
        "modal-append-to-body": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ],
          ref: "itemForm",
          attrs: {
            model: _vm.itemForm,
            rules: _vm.itemFormRules,
            "label-width": "100px",
            size: "mini",
            "label-suffix": ":"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            },
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.formSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "flex-wrap": "wrap" },
              attrs: { type: "flex", gutter: 20 }
            },
            [
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.itemForm.id,
                      expression: "!itemForm.id"
                    }
                  ],
                  attrs: { span: 12 }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物资类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.typeChange },
                          model: {
                            value: _vm.itemForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "type", $$v)
                            },
                            expression: "itemForm.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "耗材" } }, [
                            _vm._v("耗材")
                          ]),
                          _c("el-radio", { attrs: { label: "工具" } }, [
                            _vm._v("工具")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物资名称", prop: "productName" } },
                    [
                      _c("el-input", {
                        staticStyle: { "text-align": "center" },
                        attrs: {
                          placeholder: "请输入物资名称",
                          maxlength: "50",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.productName,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "productName", $$v)
                          },
                          expression: "itemForm.productName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌", prop: "brandId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择品牌",
                            disabled: true
                          },
                          model: {
                            value: _vm.itemForm.brandId,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "brandId", $$v)
                            },
                            expression: "itemForm.brandId"
                          }
                        },
                        _vm._l(_vm.brandList, function(item) {
                          return _c("el-option", {
                            key: item.ID,
                            attrs: { label: item.name, value: item.ID }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产地", prop: "productPlace" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入产地",
                          maxlength: "100",
                          "show-word-limit": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.itemForm.productPlace,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "productPlace", $$v)
                          },
                          expression: "itemForm.productPlace"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属分类", prop: "classification" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请选择所属分类",
                            disabled: true
                          },
                          model: {
                            value: _vm.itemForm.classificationText,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "classificationText", $$v)
                            },
                            expression: "itemForm.classificationText"
                          }
                        },
                        [
                          _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-more" },
                            on: { click: _vm.categorySelect },
                            slot: "append"
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产厂家", prop: "manufacturer" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入生产厂家",
                          disabled: true
                        },
                        model: {
                          value: _vm.itemForm.manufacturer,
                          callback: function($$v) {
                            _vm.$set(_vm.itemForm, "manufacturer", $$v)
                          },
                          expression: "itemForm.manufacturer"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "注册证号", prop: "registrationNumber" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择注册证号"
                              },
                              on: { change: _vm.registrationNumberChange },
                              model: {
                                value: _vm.itemForm.registrationNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.itemForm,
                                    "registrationNumber",
                                    $$v
                                  )
                                },
                                expression: "itemForm.registrationNumber"
                              }
                            },
                            _vm._l(_vm.registrationList, function(item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.ID,
                                  attrs: {
                                    label: item.regNoCn,
                                    value: item.regNoCn,
                                    disabled: item.state !== 1
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.regNoCn))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "12px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.regProductName))]
                                  )
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 5px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.registrationAdd }
                            },
                            [_vm._v("新增注册证")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "注册日期" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.registrationRow.regDate) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "失效日期" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.registrationRow.expirationDate) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "效期产生策略" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.itemForm.ep_month_check,
                              callback: function($$v) {
                                _vm.$set(_vm.itemForm, "ep_month_check", $$v)
                              },
                              expression: "itemForm.ep_month_check"
                            }
                          },
                          [_vm._v("按生产日期往后推算产生")]
                        ),
                        _c("el-input-number", {
                          staticStyle: { width: "80px", margin: "0 5px" },
                          attrs: {
                            "controls-position": "right",
                            min: 0,
                            disabled:
                              _vm.itemForm.ep_month_check === 0 ? true : false
                          },
                          model: {
                            value: _vm.itemForm.ep_month,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "ep_month", $$v)
                            },
                            expression: "itemForm.ep_month"
                          }
                        }),
                        _vm._v(" 个月 ")
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "效期预警策略" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            attrs: { "true-label": 1, "false-label": 0 },
                            model: {
                              value: _vm.itemForm.ep_day_check,
                              callback: function($$v) {
                                _vm.$set(_vm.itemForm, "ep_day_check", $$v)
                              },
                              expression: "itemForm.ep_day_check"
                            }
                          },
                          [_vm._v("提前")]
                        ),
                        _vm._v(" "),
                        _c("el-input-number", {
                          staticStyle: { width: "100px", margin: "0 10px" },
                          attrs: { "controls-position": "right", min: 0 },
                          model: {
                            value: _vm.itemForm.ep_day,
                            callback: function($$v) {
                              _vm.$set(_vm.itemForm, "ep_day", $$v)
                            },
                            expression: "itemForm.ep_day"
                          }
                        }),
                        _vm._v(" 天 ")
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.itemForm.id
        ? _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading"
                }
              ],
              ref: "specForm",
              attrs: {
                model: _vm.specForm,
                rules: _vm.specFormRules,
                "label-width": "100px",
                size: "mini",
                "label-suffix": ":"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.formSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-wrap": "wrap" },
                  attrs: { type: "flex" }
                },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: { "font-size": "18px", margin: "10px 0" }
                      },
                      [_vm._v("新增规格")]
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品编码", prop: "productNumber" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入产品编码",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.productNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "productNumber", $$v)
                              },
                              expression: "specForm.productNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.itemForm.type === "工具",
                          expression: "itemForm.type==='工具'"
                        }
                      ],
                      attrs: { span: 8 }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "工具名称", prop: "toolName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入工具名称",
                              clearable: "",
                              maxlength: "50"
                            },
                            model: {
                              value: _vm.specForm.toolName,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "toolName", $$v)
                              },
                              expression: "specForm.toolName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.itemForm.type === "工具",
                          expression: "itemForm.type==='工具'"
                        }
                      ],
                      attrs: { span: 8 }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "注册证号",
                            prop: "registrationNumber"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择注册证号",
                                clearable: ""
                              },
                              model: {
                                value: _vm.specForm.registrationNumber,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.specForm,
                                    "registrationNumber",
                                    $$v
                                  )
                                },
                                expression: "specForm.registrationNumber"
                              }
                            },
                            _vm._l(_vm.registrationList, function(item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.ID,
                                  attrs: {
                                    label: item.regNoCn,
                                    value: item.regNoCn,
                                    disabled: item.state !== 1
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v(_vm._s(item.regNoCn))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        color: "#8492a6",
                                        "font-size": "12px"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.regProductName))]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "条码主码", prop: "barCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入条码主码",
                              clearable: "",
                              oninput:
                                "value=value.replace(/[^\\w\\.\\/]/ig,'')"
                            },
                            model: {
                              value: _vm.specForm.barCode,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "barCode", $$v)
                              },
                              expression: "specForm.barCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "规格型号",
                            prop: "specificationModel"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入规格型号",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.specificationModel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "specificationModel",
                                  $$v
                                )
                              },
                              expression: "specForm.specificationModel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位", prop: "unit" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择单位"
                              },
                              model: {
                                value: _vm.specForm.unit,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "unit", $$v)
                                },
                                expression: "specForm.unit"
                              }
                            },
                            _vm._l(_vm.packUnitList, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "包装数量", prop: "packNum" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入包装数量",
                              step: 1,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.packNum,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "packNum", $$v)
                              },
                              expression: "specForm.packNum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "包装单位", prop: "packUnit" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择包装单位"
                              },
                              model: {
                                value: _vm.specForm.packUnit,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "packUnit", $$v)
                                },
                                expression: "specForm.packUnit"
                              }
                            },
                            _vm._l(_vm.packUnitList, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "包装说明", prop: "packInstructions" }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入包装说明",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.packInstructions,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "packInstructions", $$v)
                              },
                              expression: "specForm.packInstructions"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "存储条件", prop: "storage" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "text",
                                placeholder: "存储条件",
                                disabled: true,
                                clearable: ""
                              },
                              model: {
                                value: _vm.specForm.storageString,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "storageString", $$v)
                                },
                                expression: "specForm.storageString"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "append" },
                                  on: { click: _vm.viewStorageList },
                                  slot: "append"
                                },
                                [_vm._v("选取")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "灭菌方式",
                            prop: "sterilizationWays"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择灭菌方式"
                              },
                              model: {
                                value: _vm.specForm.sterilizationWays,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.specForm,
                                    "sterilizationWays",
                                    $$v
                                  )
                                },
                                expression: "specForm.sterilizationWays"
                              }
                            },
                            _vm._l(_vm.sterilizationWaysList, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "材质", prop: "material" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择材质"
                              },
                              model: {
                                value: _vm.specForm.material,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "material", $$v)
                                },
                                expression: "specForm.material"
                              }
                            },
                            _vm._l(_vm.materialList, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "批发进价",
                            prop: "wholesalePurchasePrice"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入批发进价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.wholesalePurchasePrice,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "wholesalePurchasePrice",
                                  $$v
                                )
                              },
                              expression: "specForm.wholesalePurchasePrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "寄售进价",
                            prop: "consignmentPurchasePrice"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入寄售进价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.consignmentPurchasePrice,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.specForm,
                                  "consignmentPurchasePrice",
                                  $$v
                                )
                              },
                              expression: "specForm.consignmentPurchasePrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "市场售价", prop: "marketPrice" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入市场售价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.marketPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "marketPrice", $$v)
                              },
                              expression: "specForm.marketPrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "寄售售价", prop: "consignmentPrice" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入寄售售价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.consignmentPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "consignmentPrice", $$v)
                              },
                              expression: "specForm.consignmentPrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "批发售价", prop: "wholesalePrice" }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入批发售价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.wholesalePrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "wholesalePrice", $$v)
                              },
                              expression: "specForm.wholesalePrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "医保编码", prop: "medCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入医保编码",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.medCode,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "medCode", $$v)
                              },
                              expression: "specForm.medCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "网采编码", prop: "netCode" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入网采编码",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.netCode,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "netCode", $$v)
                              },
                              expression: "specForm.netCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "网采价", prop: "onlPrice" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: "请输入网采价",
                              step: 0.01,
                              "controls-position": "right"
                            },
                            model: {
                              value: _vm.specForm.onlPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "onlPrice", $$v)
                              },
                              expression: "specForm.onlPrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否集采类耗材",
                            prop: "centralized"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.specForm.centralized,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "centralized", $$v)
                                },
                                expression: "specForm.centralized"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("否")
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("是")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "货币单位", prop: "monetaryUnit" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                placeholder: "请选择货币单位"
                              },
                              model: {
                                value: _vm.specForm.monetaryUnit,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "monetaryUnit", $$v)
                                },
                                expression: "specForm.monetaryUnit"
                              }
                            },
                            _vm._l(_vm.monetaryUnitList, function(item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "英文描述", prop: "genericNameEn" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入英文描述",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.genericNameEn,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "genericNameEn", $$v)
                              },
                              expression: "specForm.genericNameEn"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "通用名称", prop: "genericName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "通用名称",
                              clearable: ""
                            },
                            model: {
                              value: _vm.specForm.genericName,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "genericName", $$v)
                              },
                              expression: "specForm.genericName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "效期产生策略", prop: "ep_month" } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.specForm.ep_month_check,
                                callback: function($$v) {
                                  _vm.$set(_vm.specForm, "ep_month_check", $$v)
                                },
                                expression: "specForm.ep_month_check"
                              }
                            },
                            [_vm._v("按生产日期往后推算产生")]
                          ),
                          _c("el-input-number", {
                            staticStyle: { width: "100px", margin: "0 10px" },
                            attrs: {
                              "controls-position": "right",
                              min: 0,
                              disabled:
                                _vm.specForm.ep_month_check === 0 ? true : false
                            },
                            model: {
                              value: _vm.specForm.ep_month,
                              callback: function($$v) {
                                _vm.$set(_vm.specForm, "ep_month", $$v)
                              },
                              expression: "specForm.ep_month"
                            }
                          }),
                          _vm._v(" 个月 ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "效期预警策略", prop: "ep_day" } },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { "true-label": 1, "false-label": 0 },
                                  model: {
                                    value: _vm.specForm.ep_day_check,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.specForm,
                                        "ep_day_check",
                                        $$v
                                      )
                                    },
                                    expression: "specForm.ep_day_check"
                                  }
                                },
                                [_vm._v("提前")]
                              ),
                              _c("el-input-number", {
                                staticStyle: {
                                  width: "100px",
                                  margin: "0 10px"
                                },
                                attrs: { "controls-position": "right", min: 0 },
                                model: {
                                  value: _vm.specForm.ep_day,
                                  callback: function($$v) {
                                    _vm.$set(_vm.specForm, "ep_day", $$v)
                                  },
                                  expression: "specForm.ep_day"
                                }
                              }),
                              _vm._v(" 天 ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading"
            }
          ]
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24, align: "center" } },
            [
              _c(
                "el-button",
                { attrs: { size: "medium" }, on: { click: _vm.dialogClose } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "prevent-re-click", rawName: "v-prevent-re-click" }
                  ],
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.formSubmit }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CategoryComponents", {
        ref: "CategoryComponents",
        on: { receive: _vm.categoryConfirm }
      }),
      _c("RegistrationFormComponents", {
        ref: "RegistrationFormComponents",
        on: { reload: _vm.registrationLoad }
      }),
      _c("StorageSelectComponents", {
        ref: "StorageSelectComponents",
        on: { confirm: _vm.storageConfirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }