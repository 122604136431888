var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售审核")]),
              _c("el-breadcrumb-item", [_vm._v("回款审核")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.form,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.formSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "客户名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入客户名称", clearable: "" },
                      model: {
                        value: _vm.form.customerName,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "customerName", $$v)
                        },
                        expression: "form.customerName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "收款公司" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          clearable: "",
                          "allow-create": "",
                          "default-first-option": "",
                          placeholder: "请选择回款公司"
                        },
                        model: {
                          value: _vm.form.subsidiaryName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "subsidiaryName", $$v)
                          },
                          expression: "form.subsidiaryName"
                        }
                      },
                      _vm._l(_vm.subsidiaryList, function(item, index) {
                        return _c("el-option", {
                          key: "subsidiaryName" + index,
                          attrs: {
                            label: item.companyName,
                            value: item.companyName
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        model: {
                          value: _vm.form.returnStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "returnStatus", $$v)
                          },
                          expression: "form.returnStatus"
                        }
                      },
                      _vm._l(_vm.returnStatusList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "回款日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            clearable: ""
                          },
                          model: {
                            value: _vm.form.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "dateRange", $$v)
                            },
                            expression: "form.dateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.formSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "font-size": "14px"
            }
          },
          [
            _c(
              "div",
              { staticClass: "tab-list-grey", staticStyle: { margin: "0" } },
              [
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "1" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("1")
                      }
                    }
                  },
                  [_vm._v("全部")]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "2" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("2")
                      }
                    }
                  },
                  [
                    _vm._v("待审核("),
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.returnStatusType1Count))
                    ]),
                    _vm._v(")")
                  ]
                ),
                _c(
                  "div",
                  {
                    class:
                      _vm.tabActiveId === "3" ? "tab-item hover" : "tab-item",
                    on: {
                      click: function($event) {
                        return _vm.tabSelect("3")
                      }
                    }
                  },
                  [_vm._v("已审核")]
                )
              ]
            ),
            _c("div")
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.dataLoading,
              expression: "dataLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasPagination: true,
            hasIndex: true,
            pagination: _vm.form,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.dataList
          },
          on: {
            pageChange: _vm.pageChange,
            sizeChange: _vm.sizeChange,
            "row-dblclick": _vm.rowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "returnStatus",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.returnStatus === 3
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: row.returnRemarks,
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "el-link",
                            { attrs: { type: row.returnStatusType } },
                            [_vm._v(_vm._s(row.returnStatusText))]
                          )
                        ],
                        1
                      )
                    : _c("el-link", { attrs: { type: row.returnStatusType } }, [
                        _vm._v(_vm._s(row.returnStatusText))
                      ])
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      row.returnStatus === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.toCheck(row)
                                }
                              }
                            },
                            [_vm._v("审核")]
                          )
                        : _vm._e(),
                      row.returnStatus === 2 && row.ssociatedInvoice === 1
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.toUnCheck(row)
                                }
                              }
                            },
                            [_vm._v("撤销审核")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.toView(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c("DetailComponent", {
          ref: "DetailComponentRef",
          on: {
            reload: function($event) {
              return _vm.loadData()
            }
          }
        })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }