import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listOutWarehouseOrder } from '@/api/stock.js';
import dayjs from 'dayjs';
export default {
  components: {},
  data: function data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      dbList: [],
      dbLoading: false,
      form: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var that = this;
      that.dialogVisible = true;
      that.dialogTitle = '临调超期未回库预警';
      that.dbList = [];
      that.dbLoading = false;
      that.form = {
        page: 1,
        pageSize: 10,
        total: 0,
        isWarning: 1,
        issueType: 2
      };
      that.loadData();
    },
    loadData: function loadData() {
      var that = this;
      that.dbLoading = true;
      that.dbList = [];
      listOutWarehouseOrder(that.form).then(function (res) {
        res.list.forEach(function (row) {
          row.lendDayDesc = row.lendDay + '天';
          row.deliveryTime = dayjs(row.deliveryTime).format('YYYY-MM-DD');
          row.overdueDaysDesc = that.epDayFormatter(row);
          that.dbList.push(row);
        });
        that.form.total = res.total;
      }).finally(function () {
        that.dbLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      var that = this;
      that.form.page = page;
      that.loadData();
    },
    dialogClose: function dialogClose() {
      var that = this;
      that.dialogVisible = false;
    },
    toSkipDetail: function toSkipDetail(row) {
      var that = this;
      that.dialogClose();
      that.$router.push({
        path: '/stock/outwarehouse/consignment/index/detail/' + row.ID + '/edit'
      });
    },
    epDayFormatter: function epDayFormatter(row) {
      if (row.status < 6 && row.status >= 3) {
        var deliveryDate = dayjs(row.deliveryTime);
        var expDate = deliveryDate.add(row.lendDay, 'day');
        var now = dayjs();

        if (expDate < now) {
          var expDays = dayjs(now).diff(expDate, 'day');
          return '<span class="text-danger">已超期' + expDays + '天</span>';
        } else {
          return '正常';
        }
      } else {
        return '正常';
      }
    }
  }
};